import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const FAQItem = ({ questionKey, answerKey }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="mb-4">
      <button
        className="flex justify-between items-center w-full text-left p-4 bg-dark-gray rounded-lg focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-primary font-semibold">{t(questionKey)}</span>
        {isOpen ? (
          <ChevronUp className="text-secondary" />
        ) : (
          <ChevronDown className="text-secondary" />
        )}
      </button>
      {isOpen && (
        <div className="mt-2 p-4 bg-dark-gray rounded-lg">
          <p className="text-primary">{t(answerKey)}</p>
        </div>
      )}
    </div>
  );
};

const FAQ = () => {
  const { t } = useTranslation();
  const faqs = [
    { questionKey: 'faq.q1', answerKey: 'faq.a1' },
    { questionKey: 'faq.q2', answerKey: 'faq.a2' },
    { questionKey: 'faq.q3', answerKey: 'faq.a3' },
    { questionKey: 'faq.q4', answerKey: 'faq.a4' },
    { questionKey: 'faq.q5', answerKey: 'faq.a5' },
    { questionKey: 'faq.q6', answerKey: 'faq.a6' }
  ];

  return (
    <section id="faq" className="py-24 bg-background">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-4 text-center text-primary">{t('faq.title')}</h2>
        <p className="text-xl text-center text-primary mb-12">{t('faq.subtitle')}</p>
        <div className="max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <FAQItem key={index} questionKey={faq.questionKey} answerKey={faq.answerKey} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;