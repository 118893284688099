import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const API_URL = 'https://recenstar-server.onrender.com';

const VerifyPhonePage = () => {
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [step, setStep] = useState('input');
  const [error, setError] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/me`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setUser(response.data.user);
      } catch (error) {
        console.error('Errore nel recupero dei dati utente:', error);
      }
    };

    fetchUser();
  }, []);

  const handleSendCode = async (e) => {
    e.preventDefault();
    setError('');
    setCodeSent(false);
    try {
      const token = localStorage.getItem('token');
      const formattedPhone = '+' + phone;
      console.log('URL invio codice:', `${API_URL}/api/send-verification-code`);
      const response = await axios.post(`${API_URL}/api/send-verification-code`, 
        { phoneNumber: formattedPhone },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      console.log('Risposta invio codice:', response.data);
      setStep('verify');
      setCodeSent(true);
    } catch (error) {
      console.error('Errore dettagliato nell\'invio del codice:', error.response || error);
      if (error.response && error.response.data.error === 'Numero di telefono già presente') {
        setError(error.response.data.message);
      } else {
        setError('Errore nell\'invio del codice. Riprova.');
      }
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const token = localStorage.getItem('token');
      const formattedPhone = '+' + phone;
      console.log('URL di verifica:', `${API_URL}/api/verify-code`);
      const response = await axios.post(`${API_URL}/api/verify-code`, 
        { phoneNumber: formattedPhone, code: verificationCode },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      
      console.log('Risposta verifica:', response.data);
      
      const updatedUser = response.data.user;
      localStorage.setItem('user', JSON.stringify(updatedUser));
      localStorage.setItem('verifiedPhoneNumber', updatedUser.verifiedPhoneNumber);
      
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        console.log('Token salvato:', localStorage.getItem('token'));
      }

      navigate('/voicebot-config');
    } catch (error) {
      console.error('Errore dettagliato nella verifica:', error.response || error);
      setError('Codice non valido. Riprova.');
    }
  };

  return (
    <div className="relative min-h-screen bg-[#0B0B0B] text-white p-4 sm:p-8 font-inter">
      <div className="relative z-10 max-w-md mx-auto mt-20">
        <h1 className="text-4xl font-bold text-[#FAD403] mb-8 text-center">
          Verifica il tuo numero di telefono
        </h1>
        {user && user.phoneNumberDeletions >= 3 ? (
          <p className="text-center text-red-500">
            Hai raggiunto il limite massimo di cancellazioni. Contatta info@recenstar.com per assistenza.
          </p>
        ) : (
          <>
            {step === 'input' ? (
              <form onSubmit={handleSendCode} className="bg-[#1E1E1E] p-6 rounded-lg shadow-lg">
                <div className="mb-4">
                  <label htmlFor="phoneNumber" className="block mb-2">Numero di telefono</label>
                  <PhoneInput
                    country={'it'}
                    value={phone}
                    onChange={setPhone}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      autoFocus: true
                    }}
                    containerClass="w-full"
                    inputClass="w-full p-2 bg-gray-700 rounded text-white border border-gray-600"
                    buttonClass="bg-gray-700 border border-gray-600 hover:bg-gray-600"
                    dropdownClass="bg-gray-700 text-white"
                    countryCodeEditable={false}
                    enableSearch={true}
                    searchClass="bg-gray-700 text-white"
                    searchStyle={{ color: 'white' }}
                    dropdownStyle={{ 
                      backgroundColor: '#1E1E1E',
                      color: 'white',
                      border: '1px solid #4a4a4a'
                    }}
                    buttonStyle={{
                      backgroundColor: '#1E1E1E',
                      borderColor: '#4a4a4a'
                    }}
                  />
                </div>
                <button type="submit" className="w-full bg-[#FAD403] text-[#0B0B0B] px-4 py-2 rounded hover:bg-opacity-90 transition duration-300">
                  Invia codice di verifica
                </button>
              </form>
            ) : (
              <form onSubmit={handleVerifyCode} className="bg-[#1E1E1E] p-6 rounded-lg shadow-lg">
                <div className="mb-4">
                  <label htmlFor="verificationCode" className="block mb-2">Codice di verifica</label>
                  <input
                    type="text"
                    id="verificationCode"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    className="w-full p-2 bg-gray-700 rounded text-white border border-gray-600"
                    required
                  />
                </div>
                <button type="submit" className="w-full bg-[#FAD403] text-[#0B0B0B] px-4 py-2 rounded hover:bg-opacity-90 transition duration-300">
                  Verifica codice
                </button>
              </form>
            )}
          </>
        )}
        {codeSent && (
          <p className="mt-4 text-green-500 text-center">Codice inviato, dovresti riceverlo a breve!</p>
        )}
        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
      </div>
    </div>
  );
};

export default VerifyPhonePage;