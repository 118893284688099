import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ParticleBackground from './ParticleBackground';
import { track } from '@vercel/analytics';

const LoginPage = ({ onPageView, onSuccessfulSignup }) => {
  const [email, setEmail] = useState('');
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [googleAuthInitialized, setGoogleAuthInitialized] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('LoginPage mounted');
    onPageView(); // Track page view
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  
    script.onload = () => {
      console.log('Google script loaded');
      if (window.google) {
        console.log('Initializing Google Auth');
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleGoogleResponse
        });
        setGoogleAuthInitialized(true);
        console.log('Google Auth initialized');
      } else {
        console.error('Google object not found');
      }
    };
  
    return () => {
      console.log('LoginPage unmounted');
      document.body.removeChild(script);
    };
  }, [onPageView]);
  
  const handleGoogleResponse = async (response) => {
    console.log('Google response received', response);
    if (!response || !response.credential) {
      console.error('Invalid Google response', response);
      setError('Risposta di Google non valida. Riprova.');
      return;
    }
    
    console.log('Token ricevuto da Google:', response.credential ? 'presente' : 'mancante');
    console.log('API URL:', process.env.REACT_APP_API_URL);
    
    try {
      console.log('Invio richiesta al backend con token');
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/google-login`, {
        token: response.credential
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Risposta dal backend:', result.data);
      console.log('Google login successful', result.data);
      if (result.data && result.data.token) {
        localStorage.setItem('token', result.data.token);
        track('Successful Google Login'); // Track successful Google login
        onSuccessfulSignup(); // Call the successful signup callback
        navigate('/area-personale');
      } else {
        throw new Error('Token mancante nella risposta del server');
      }
    } catch (error) {
      console.error('Errore dettagliato durante il login con Google:', error.response || error);
      setError(error.response?.data?.error || error.message || 'Errore durante l\'accesso con Google. Riprova.');
    }
  };
  
  const handleMagicLinkRequest = async (e) => {
    e.preventDefault();
    console.log('Magic Link request initiated');
    setError('');
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/request-magic-link`, { email });
      console.log('Magic Link request successful', response.data);
      setMagicLinkSent(true);
      track('Magic Link Requested'); // Track magic link request
    } catch (error) {
      console.error('Error during Magic Link request:', error);
      setError(error.response?.data?.error || 'Si è verificato un errore durante l\'invio del Magic Link');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    if (googleAuthInitialized) {
      window.google.accounts.id.prompt();
      track('Google Login Initiated'); // Track Google login initiation
    } else {
      console.error('Google Auth non è ancora inizializzato');
      setError('Errore di inizializzazione Google Auth. Riprova più tardi.');
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center bg-[#0B0B0B] py-12 px-4 sm:px-6 lg:px-8 font-inter">
      <ParticleBackground />
      <div className="z-10 max-w-md w-full space-y-8 bg-[#1E1E1E] p-10 rounded-lg shadow-xl">
        <div>
          <div className="text-[#FAD403] text-4xl mb-2">✦</div>
          <h2 className="mt-6 text-center text-3xl font-bold text-white">
            Accedi al tuo account
          </h2>
        </div>
        {!magicLinkSent ? (
          <form className="mt-8 space-y-6" onSubmit={handleMagicLinkRequest}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-700 placeholder-gray-500 text-white focus:outline-none focus:ring-[#FAD403] focus:border-[#FAD403] focus:z-10 sm:text-sm bg-[#1E1E1E]"
                  placeholder="Indirizzo email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isLoading}
                />
              </div>
            </div>
  
            {error && <p className="text-red-500 text-center">{error}</p>}
  
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-[#0B0B0B] bg-[#FAD403] hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FAD403] disabled:opacity-50"
                disabled={isLoading}
              >
                {isLoading ? 'Invio in corso...' : 'Invia Magic Link'}
              </button>
            </div>
          </form>
        ) : (
          <div className="mt-8 text-center text-white">
            <p>Abbiamo inviato un magic link al tuo indirizzo email.</p>
            <p>Controlla la tua casella di posta, anche in SPAM, e clicca sul link per accedere!</p>
            <button
              onClick={() => setMagicLinkSent(false)}
              className="mt-4 text-[#FAD403] hover:text-opacity-90"
            >
              Invia di nuovo
            </button>
          </div>
        )}
        <div>
          <button
            onClick={handleGoogleLogin}
            className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
            disabled={isLoading}
          >
            {isLoading ? 'Accesso in corso...' : 'Accedi con Google'}
          </button>
        </div>
        <div className="text-center mt-4">
          <Link to="/" className="font-medium text-[#FAD403] hover:text-opacity-90">
            Torna in Homepage
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;