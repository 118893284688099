import React, { useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const ComparisonSlider = ({ beforeImage, afterImage, beforeLabel, afterLabel }) => {
  const [position, setPosition] = useState(50);

  const handlePositionChange = (newPosition) => {
    setPosition(newPosition);
  };

  return (
    <div className="relative w-full h-full">
      <ReactCompareSlider
        itemOne={
          <ReactCompareSliderImage
            src={beforeImage}
            alt="Before Recenstar"
          />
        }
        itemTwo={
          <ReactCompareSliderImage
            src={afterImage}
            alt="After Recenstar"
          />
        }
        position={position}
        onPositionChange={handlePositionChange}
        style={{
          height: '100%',
          width: '100%',
        }}
      />
      {position > 5 && (
        <div 
          className="absolute top-2 left-2 bg-secondary text-background font-medium text-xs sm:text-sm px-2 py-1 rounded-full transition-all duration-300 ease-in-out"
          style={{ backgroundColor: '#FAD403' }}
        >
          {beforeLabel}
        </div>
      )}
      {position < 95 && (
        <div 
          className="absolute top-2 right-2 bg-secondary text-background font-medium text-xs sm:text-sm px-2 py-1 rounded-full transition-all duration-300 ease-in-out"
          style={{ backgroundColor: '#FAD403' }}
        >
          {afterLabel}
        </div>
      )}
    </div>
  );
};

export default ComparisonSlider;