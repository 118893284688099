import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import logo from '../src/assets/logo.png';
import italianFlag from '../src/assets/italian-flag.svg';
import ukFlag from '../src/assets/uk-flag.svg';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setIsMenuOpen(false);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const FlagButton = ({ language, flag, alt }) => (
    <button 
      onClick={() => changeLanguage(language)} 
      className="w-6 h-6 rounded-full overflow-hidden hover:opacity-80 transition-opacity duration-300 focus:outline-none focus:ring-2 focus:ring-[#FAD403]"
    >
      <img src={flag} alt={alt} className="w-full h-full object-cover" />
    </button>
  );

  const NavItem = ({ sectionId, textKey }) => (
    <button
      onClick={() => scrollToSection(sectionId)}
      className="text-white hover:text-[#FAD403] transition-colors duration-300"
    >
      {t(`common.${textKey}`)}
    </button>
  );

  const navItems = [
    { sectionId: 'features', textKey: 'features' },
    { sectionId: 'benefits', textKey: 'business' },
    { sectionId: 'reasons', textKey: 'magic' },
    { sectionId: 'faq', textKey: 'solutions' },
  ];

  return (
    <header className={`bg-[#0B0B0B] text-white py-4 transition-all duration-300 ${isSticky ? 'fixed top-0 left-0 right-0 z-50 shadow-md' : ''}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img src={logo} alt="Logo" className="h-12 w-auto" />
          </Link>
          
          <nav className="hidden md:flex space-x-8">
            {navItems.map((item) => (
              <NavItem key={item.sectionId} sectionId={item.sectionId} textKey={item.textKey} />
            ))}
          </nav>
          
          <div className="hidden md:flex items-center space-x-4">
            <div className="flex space-x-2">
              <FlagButton language="it" flag={italianFlag} alt="Italiano" />
              <FlagButton language="en" flag={ukFlag} alt="English" />
            </div>
            <Link to="/login" className="bg-[#FAD403] text-[#0B0B0B] px-4 py-2 rounded-full font-bold hover:bg-opacity-90 transition-opacity duration-300">
              {t('common.tryDemoFree')}
            </Link>
          </div>
          
          <button onClick={toggleMenu} className="md:hidden text-white">
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>
      
      {isMenuOpen && (
        <div className="md:hidden px-2 pt-2 pb-3 space-y-1">
          {navItems.map((item) => (
            <button 
              key={item.sectionId}
              onClick={() => scrollToSection(item.sectionId)} 
              className="block w-full text-left text-white hover:text-[#FAD403] px-3 py-2"
            >
              {t(`common.${item.textKey}`)}
            </button>
          ))}
          <div className="flex justify-center space-x-4 mt-2">
            <FlagButton language="it" flag={italianFlag} alt="Italiano" />
            <FlagButton language="en" flag={ukFlag} alt="English" />
          </div>
          <Link to="/login" className="block w-full bg-[#FAD403] text-[#0B0B0B] px-4 py-2 rounded-full font-bold hover:bg-opacity-90 mt-2 text-center">
            {t('common.tryDemoFree')}
          </Link>
        </div>
      )}
    </header>
  );
};

export default Header;