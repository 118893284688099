import React, { useState, useEffect } from 'react';

const EditableOrderConfirmation = ({ initialOrderDetails, onConfirm, onCancel, onAnnulla, config }) => {
  console.log("Props ricevute in EditableOrderConfirmation:", { initialOrderDetails, config });
  const [orderDetails, setOrderDetails] = useState(initialOrderDetails);
  const [availableItems, setAvailableItems] = useState([]);
  const [errors, setErrors] = useState({});
  
    useEffect(() => {
      console.log("Tipo di azienda:", config.tipologiaAzienda);
      console.log("Richiede consegna:", config.requiresDelivery);
      if (initialOrderDetails && initialOrderDetails.availableItems) {
        setAvailableItems(initialOrderDetails.availableItems);
      } else if (config && config.menu) {
        setAvailableItems(config.menu);
      }
      setOrderDetails(initialOrderDetails);
    }, [initialOrderDetails, config]);
  
    const validateForm = () => {
      const newErrors = {};
      if (!orderDetails.additionalInfo.name) {
        newErrors.name = "Il nome è obbligatorio";
      }
      if (!orderDetails.additionalInfo.phone) {
        newErrors.phone = "Il numero di telefono è obbligatorio";
      }
      
      if (config.tipologiaAzienda === 'Ristorante' || config.tipologiaAzienda === 'Ecommerce') {
        if (!orderDetails.additionalInfo.address) {
          newErrors.address = "L'indirizzo è obbligatorio";
        }
      } else {
        if (!orderDetails.additionalInfo.datetime) {
          newErrors.datetime = "La data e l'ora sono obbligatorie";
        }
      }

      if (orderDetails.items.every(item => item.quantita === 0)) {
        newErrors.items = "Seleziona almeno un prodotto";
      }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleConfirmOrder = () => {
      if (validateForm()) {
        onConfirm(orderDetails);
      }
    };

    const updateOrderItem = (itemName, quantity) => {
      const updatedItems = availableItems.map(item => {
        if (item.nome === itemName) {
          return { ...item, quantita: parseInt(quantity) || 0 };
        }
        const existingItem = orderDetails.items.find(i => i.nome === item.nome);
        return existingItem || { ...item, quantita: 0 };
      });
    
      const filteredItems = updatedItems.filter(item => item.quantita > 0);
      const totalAmount = filteredItems.reduce((sum, item) => sum + (item.prezzo * item.quantita), 0);
    
      setOrderDetails(prev => ({
        ...prev,
        items: filteredItems,
        totalAmount
      }));
    };

  const updateAdditionalInfo = (key, value) => {
    setOrderDetails({
      ...orderDetails,
      additionalInfo: {
        ...orderDetails.additionalInfo,
        [key]: value
      }
    });
    // Rimuovi l'errore quando l'utente inizia a digitare
    if (errors[key]) {
      setErrors({ ...errors, [key]: null });
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-xl">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">
        {config.tipologiaAzienda === 'Ristorante' || config.tipologiaAzienda === 'Ecommerce' 
          ? "Modifica il tuo ordine" 
          : "Modifica la tua prenotazione"}
      </h2>
      <div className="mb-6">
        <h3 className="font-semibold mb-2 text-gray-700">
          {config.tipologiaAzienda === 'Ristorante' || config.tipologiaAzienda === 'Ecommerce' 
            ? "Prodotti:" 
            : "Servizi:"}
        </h3>
        {availableItems.map((item) => (
          <div key={item.nome} className="flex items-center justify-between mb-2">
            <span className="text-gray-800">{item.nome} - €{item.prezzo.toFixed(2)}</span>
            <input
              type="number"
              min="0"
              value={orderDetails.items.find(i => i.nome === item.nome)?.quantita || 0}
              onChange={(e) => updateOrderItem(item.nome, e.target.value)}
              className={`w-20 p-1 border rounded text-gray-800 bg-white ${errors.items ? 'border-red-500' : ''}`}
            />
          </div>
        ))}
        {errors.items && <p className="text-red-500 text-sm mt-1">{errors.items}</p>}
      </div>
      <div className="mb-6">
  <h3 className="font-semibold mb-2 text-gray-700">Informazioni di contatto:</h3>
  <div className="mb-2">
    <input
      type="text"
      value={orderDetails.additionalInfo.name || ''}
      onChange={(e) => updateAdditionalInfo('name', e.target.value)}
      placeholder="Nome"
      className={`w-full p-2 border rounded text-gray-800 bg-white ${errors.name ? 'border-red-500' : ''}`}
    />
    {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
  </div>
  <div className="mb-2">
    <input
      type="tel"
      value={orderDetails.additionalInfo.phone || ''}
      onChange={(e) => updateAdditionalInfo('phone', e.target.value)}
      placeholder="Telefono"
      className={`w-full p-2 border rounded text-gray-800 bg-white ${errors.phone ? 'border-red-500' : ''}`}
    />
    {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
  </div>
  {(config.requiresDelivery || config.tipologiaAzienda === 'Ristorante' || config.tipologiaAzienda === 'Ecommerce') ? (
  <div className="mb-2">
    <input
      type="text"
      value={orderDetails.additionalInfo.address || ''}
      onChange={(e) => updateAdditionalInfo('address', e.target.value)}
      placeholder="Indirizzo di consegna"
      className={`w-full p-2 border rounded text-gray-800 bg-white ${errors.address ? 'border-red-500' : ''}`}
    />
    {errors.address && <p className="text-red-500 text-sm mt-1">{errors.address}</p>}
  </div>
) : (
  <div className="mb-2">
    <input
      type="datetime-local"
      value={orderDetails.additionalInfo.datetime || ''}
      onChange={(e) => updateAdditionalInfo('datetime', e.target.value)}
      className={`w-full p-2 border rounded text-gray-800 bg-white ${errors.datetime ? 'border-red-500' : ''}`}
    />
    {errors.datetime && <p className="text-red-500 text-sm mt-1">{errors.datetime}</p>}
  </div>
)}
</div>
      <div className="bg-blue-100 p-4 rounded-lg mb-6">
        <p className="font-bold text-blue-800">
          {config.tipologiaAzienda === 'Ristorante' || config.tipologiaAzienda === 'Ecommerce' 
            ? `Totale ordine: €${orderDetails.totalAmount.toFixed(2)}`
            : `Totale prenotazione: €${orderDetails.totalAmount.toFixed(2)}`}
        </p>
      </div>
      <div className="flex justify-end space-x-4">
        <button onClick={onAnnulla} className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500 transition-colors">
          Annulla
        </button>
        <button onClick={onCancel} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors">
          Modifica
        </button>
        <button 
          onClick={handleConfirmOrder}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
        >
          {config.tipologiaAzienda === 'Ristorante' || config.tipologiaAzienda === 'Ecommerce' 
            ? "Conferma Ordine!" 
            : "Conferma Prenotazione!"}
        </button>
      </div>
    </div>
  );
};

export default EditableOrderConfirmation;