import React from 'react';
import YouTube from 'react-youtube';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Reasons = () => {
  const { t } = useTranslation();
  
  const opts = {
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <section id="reasons" className="py-24 bg-background text-primary">
      <div className="container mx-auto px-4 text-center max-w-6xl">
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 animate-fade-in-down">
          {t('reasons.title')}
        </h2>
        <p className="text-xl md:text-2xl lg:text-3xl mb-12 animate-fade-in-up max-w-4xl mx-auto">
          {t('reasons.subtitle')}
        </p>
        <div className="youtube-container mb-12 md:mb-16">
          <YouTube 
            videoId="OHfF3P_NsGQ" 
            opts={opts} 
            className="absolute top-0 left-0 w-full h-full"
          />
        </div>
        <Link 
          to="/login" 
          className="cta-button text-lg px-8 py-3 rounded-full inline-block"
        >
          {t('reasons.ctaButton')}
        </Link>
      </div>
    </section>
  );
};

export default Reasons;