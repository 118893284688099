import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';

const AdminStats = () => {
  console.log('AdminStats rendered'); // Debug log

  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { admin } = useAuth();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch('${process.env.REACT_APP_API_URL}/api/admin/stats', {
          headers: {
            'Authorization': `Bearer ${admin.token}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch stats');
        }
        const data = await response.json();
        console.log('Fetched stats:', data); // Debug log
        setStats(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching stats:', err); // Debug log
        setError(err.message);
        setLoading(false);
      }
    };

    fetchStats();
  }, [admin.token]);

  if (loading) return <div className="text-admin-text">Loading...</div>;
  if (error) return <div className="text-admin-accent">Error: {error}</div>;
  if (!stats) return <div className="text-admin-accent">No stats available</div>;

  // Dati di esempio per i grafici (in un'app reale, questi dati verrebbero dal backend)
  const userGrowthData = [
    { name: 'Jan', users: 400 },
    { name: 'Feb', users: 300 },
    { name: 'Mar', users: 500 },
    { name: 'Apr', users: 280 },
    { name: 'May', users: 590 },
    { name: 'Jun', users: 800 },
  ];

  const activityData = [
    { name: 'Voicebot', calls: stats.totalVoicebotCalls },
    { name: 'Chatbot', messages: stats.totalChatbotMessages },
  ];

  return (
    <div className="container mx-auto px-4 py-8 bg-admin-bg text-admin-text">
      <h2 className="text-2xl font-bold mb-4 text-admin-accent">Application Statistics</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <div className="bg-admin-secondary p-4 rounded shadow">
          <h3 className="text-lg font-semibold mb-2 text-admin-accent">Total Users</h3>
          <p className="text-3xl font-bold text-admin-text">{stats.totalUsers}</p>
        </div>
        <div className="bg-admin-secondary p-4 rounded shadow">
          <h3 className="text-lg font-semibold mb-2 text-admin-accent">Active Users</h3>
          <p className="text-3xl font-bold text-admin-text">{stats.activeUsers}</p>
        </div>
        <div className="bg-admin-secondary p-4 rounded shadow">
          <h3 className="text-lg font-semibold mb-2 text-admin-accent">Total Voicebot Calls</h3>
          <p className="text-3xl font-bold text-admin-text">{stats.totalVoicebotCalls}</p>
        </div>
        <div className="bg-admin-secondary p-4 rounded shadow">
          <h3 className="text-lg font-semibold mb-2 text-admin-accent">Total Chatbot Messages</h3>
          <p className="text-3xl font-bold text-admin-text">{stats.totalChatbotMessages}</p>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-bold mb-4 text-admin-accent">User Growth Over Time</h3>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={userGrowthData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" stroke="#FAD403" />
            <YAxis stroke="#FAD403" />
            <Tooltip contentStyle={{ backgroundColor: '#1E1E1E', border: 'none' }} />
            <Legend />
            <Line type="monotone" dataKey="users" stroke="#FAD403" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div>
        <h3 className="text-xl font-bold mb-4 text-admin-accent">Activity Comparison</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={activityData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" stroke="#FAD403" />
            <YAxis stroke="#FAD403" />
            <Tooltip contentStyle={{ backgroundColor: '#1E1E1E', border: 'none' }} />
            <Legend />
            <Bar dataKey="calls" fill="#8884d8" name="Voicebot Calls" />
            <Bar dataKey="messages" fill="#82ca9d" name="Chatbot Messages" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AdminStats;