import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const API_URL = 'https://recenstar-server.onrender.com'; // Sostituisci con l'URL corretto del tuo backend

const CallModal = ({ call, onClose }) => {
  if (!call) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={onClose}>
      <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-gray-800" onClick={e => e.stopPropagation()}>
        <div className="mt-3 text-center">
          <h3 className="text-2xl font-bold text-white mb-4">Call Details</h3>
          <div className="mt-2 px-7 py-3 text-left">
            <p className="text-white"><strong>Date:</strong> {call.date}</p>
            <p className="text-white"><strong>Duration:</strong> {call.duration.toFixed(2)} min</p>
            <p className="text-white"><strong>Cost:</strong> ${call.cost.toFixed(2)}</p>
            <p className="text-white"><strong>Status:</strong> {call.status}</p>
            <p className="text-white"><strong>From:</strong> {call.from}</p>
            <p className="text-white"><strong>To:</strong> {call.to}</p>
            <p className="text-white"><strong>Answered By:</strong> {call.answeredBy}</p>
            
            {call.recordingUrl && (
              <div className="mt-4">
                <h4 className="text-lg font-semibold text-white">Recording</h4>
                <audio controls src={call.recordingUrl} className="mt-2 w-full" />
              </div>
            )}
            
            {call.summary && (
              <div className="mt-4">
                <h4 className="text-lg font-semibold text-white">Summary</h4>
                <p className="text-white">{call.summary}</p>
              </div>
            )}
            
            <div className="mt-4">
              <h4 className="text-lg font-semibold text-white">Conversation</h4>
              <div className="max-h-60 overflow-y-auto mt-2">
                {call.transcript ? (
                  <p className="text-white whitespace-pre-wrap">{call.transcript}</p>
                ) : (
                  <p className="text-white">No conversation data available.</p>
                )}
              </div>
            </div>
          </div>
          <div className="items-center px-4 py-3">
            <button 
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CallAnalytics = () => {
  const [callData, setCallData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCall, setSelectedCall] = useState(null);

  useEffect(() => {
    const fetchCallData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/voicebot/call-data`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        console.log('Received call data:', response.data);
        setCallData(response.data);
      } catch (err) {
        console.error('Error fetching call data:', err);
        setError('Failed to fetch call data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchCallData();
  }, []);

  const handleCallSelect = (call) => {
    console.log('Selected call:', call);
    setSelectedCall(call);
  };

  if (loading) return <div className="text-white">Loading call data...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!Array.isArray(callData) || callData.length === 0) return <div className="text-white">No calls found for your account.</div>;

  const totalCalls = callData.length;
  const totalDuration = callData.reduce((sum, call) => sum + (call.duration || 0), 0);
  const averageDuration = totalCalls > 0 ? totalDuration / totalCalls : 0;

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-xl">
      <h2 className="text-2xl font-bold mb-4 text-white">Call Analytics</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div className="bg-gray-700 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2 text-white">Total Calls</h3>
          <p className="text-2xl font-bold text-indigo-400">{totalCalls}</p>
        </div>
        <div className="bg-gray-700 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-2 text-white">Average Duration</h3>
          <p className="text-2xl font-bold text-indigo-400">{averageDuration.toFixed(2)} min</p>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={callData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="duration" stroke="#8884d8" name="Call Duration (minutes)" />
          <Line type="monotone" dataKey="cost" stroke="#82ca9d" name="Call Cost ($)" />
        </LineChart>
      </ResponsiveContainer>
      
      <div className="mt-8">
        <h3 className="text-xl font-semibold mb-4 text-white">Call List</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {callData.map((call, index) => (
            <div key={index} className="bg-gray-700 p-4 rounded-lg cursor-pointer hover:bg-gray-600" onClick={() => handleCallSelect(call)}>
              <p className="text-white"><strong>Date:</strong> {call.date}</p>
              <p className="text-white"><strong>Duration:</strong> {call.duration ? call.duration.toFixed(2) : 'N/A'} min</p>
              <p className="text-white"><strong>Cost:</strong> ${call.cost ? call.cost.toFixed(2) : 'N/A'}</p>
              <p className="text-white"><strong>Status:</strong> {call.status}</p>
            </div>
          ))}
        </div>
      </div>

      {selectedCall && (
        <CallModal call={selectedCall} onClose={() => setSelectedCall(null)} />
      )}
    </div>
  );
};

export default CallAnalytics;