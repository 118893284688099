import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ParticleBackground from './ParticleBackground';
import CallAnalytics from './CallAnalytics';

const API_URL = 'https://recenstar-server.onrender.com'; // Sostituisci con l'URL corretto del tuo backend

const VoiceBotAnalytics = () => {
  const [callData, setCallData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCallData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/voicebot/call-data`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setCallData(response.data);
      } catch (err) {
        console.error('Errore nel recupero dei dati delle chiamate:', err);
        setError('Si è verificato un errore nel caricamento dei dati delle chiamate.');
      } finally {
        setLoading(false);
      }
    };

    fetchCallData();
  }, []);

  return (
    <div className="relative min-h-screen bg-gray-900 flex flex-col items-center justify-center p-4">
      <ParticleBackground />
      <div className="relative z-10 w-full max-w-4xl text-center mb-8">
        <h1 className="text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-500 to-pink-500">
          VoiceBot Dashboard
        </h1>
        {loading ? (
          <p className="text-white">Caricamento in corso...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <CallAnalytics callData={callData} />
        )}
      </div>
      <div className="relative z-10 mt-8">
        <Link to="/area-personale" className="bg-indigo-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-indigo-700 transition duration-300 inline-block">
          Torna all'Area Personale
        </Link>
      </div>
    </div>
  );
};

export default VoiceBotAnalytics;