import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from '../src/assets/logo.png';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-[#1E1E1E] text-white py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 mb-8 md:mb-0 flex flex-col items-center text-center">
            <img src={logo} alt="Recenstar Logo" className="h-12 mb-4" />
            <p className="text-sm">{t('footer.slogan')}</p>
          </div>
          <div className="w-full md:w-1/3 mb-8 md:mb-0 flex flex-col items-center text-center">
            <h4 className="text-xl font-semibold mb-4">Recenstar</h4>
            <ul className="space-y-2">
              <li><a href="#features" className="hover:text-[#FAD403] transition-colors">{t('footer.features')}</a></li>
              <li><a href="#benefits" className="hover:text-[#FAD403] transition-colors">{t('footer.benefits')}</a></li>
              <li><a href="#faq" className="hover:text-[#FAD403] transition-colors">{t('footer.faq')}</a></li>
              <li><a href="#testimonials" className="hover:text-[#FAD403] transition-colors">{t('footer.testimonials')}</a></li>
              <li>
                <Link to="/privacy-policy" className="hover:text-[#FAD403] transition-colors">
                  {t('footer.privacyPolicy')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/3 flex flex-col items-center text-center">
            <h4 className="text-xl font-semibold mb-4">{t('footer.contacts')}</h4>
            <p className="mb-2">{t('footer.email')}: info@recenstar.com</p>
            <div className="flex space-x-4 mt-4">
              <a href="https://www.facebook.com/recenstar" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#FAD403] transition-colors">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com/recenstar" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#FAD403] transition-colors">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.linkedin.com/company/recenstar" target="_blank" rel="noopener noreferrer" className="text-white hover:text-[#FAD403] transition-colors">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-700 text-center">
          <p>{t('footer.copyright', { year: new Date().getFullYear() })}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;