{
  "common": {
    "welcome": "Welcome to Recenstar",
    "login": "Log in",
    "register": "Sign up",
    "features": "Features",
    "business": "Business",
    "magic": "Magic",
    "solutions": "Solutions",
    "tryDemoFree": "Try Now for Free",
    "loading": "Loading...",
    "backToHome": "Back to Home",
    "save": "Save",
    "cancel": "Cancel",
    "edit": "Edit",
    "logout": "Logout"
  },
  "categories": {
    "restaurants": "Restaurants",
    "medicalOffices": "Medical Offices",
    "beautyCenters": "Beauty Centers",
    "gyms": "Gyms",
    "ecommerce": "E-commerce",
    "hotels": "Hotels"
  },
  "benefits": {
    "mainTitle": "AI In Your Business = Incredible Competitive Advantage",
    "subTitle": "If you think AI won't make a difference in your business, you're greatly mistaken. Let's look at just a few examples.",
    "withoutRecenstar": "Without Recenstar",
    "withRecenstar": "With Recenstar",
    "restaurants": {
      "title": "Restaurants",
      "description": "Imagine not having to manage reservations and reviews anymore. With Recenstar's AI, everything is automatic 24/7. No more chasing customers for reviews: our system does it for you, ensuring positive feedback on Google and TripAdvisor. This increases your online visibility and customer satisfaction. AI simplifies operations, eliminating errors and freeing up time for you to focus on what you love: offering an unforgettable culinary experience. With Recenstar, your restaurant never sleeps and success is guaranteed!",
      "beforeImage": "/ristorante1.jpeg",
      "afterImage": "/ristorante2.jpg"
    },
    "medicalOffices": {
      "title": "Medical Offices",
      "description": "Managing appointments and cancellations can be a nightmare for medical offices. Imagine an assistant working 24/7 without getting tired: with Recenstar's AI, it's possible. Appointments and cancellations are handled automatically, zero errors, maximum efficiency. Patients book and cancel easily, everything runs smoothly. The AI sends feedback requests after each visit, improving your online reputation. With Recenstar, every interaction is perfect, every patient is happy, and your office runs like a Swiss watch. Going to the doctor will no longer be a nightmare (but we can't guarantee that).",
      "beforeImage": "/medico1.jpg",
      "afterImage": "/medico2.jpg"
    },
    "beautyCenters": {
      "title": "Beauty Centers",
      "description": "Asking for reviews in beauty centers is embarrassing and costly. With Recenstar's AI, you won't have to worry about it anymore. Our system automatically requests feedback, increasing your online visibility. Imagine an assistant that manages bookings, sends reminders, and collects reviews, effortlessly. It improves efficiency and allows you to focus on what you love: making clients feel their best. With Recenstar, every client will feel special and your wellness center will shine like never before!",
      "beforeImage": "/spa1.jpeg",
      "afterImage": "/spa2.jpg"
    },
    "gyms": {
      "title": "Gyms",
      "description": "Managing bookings and communicating with members can be chaotic. Imagine a system that automates everything. With Recenstar's AI, bookings for classes and sessions with personal trainers are handled effortlessly. Communicate directly via WhatsApp and Facebook, answering questions and sending promotions. This increases engagement and customer satisfaction, making them feel heard and valued. With Recenstar, your gym becomes organized and welcoming, an active and engaging community!",
      "beforeImage": "/palestra1.jpg",
      "afterImage": "/palestra2.jpg"
    },
    "ecommerce": {
      "title": "E-commerce",
      "description": "Managing reviews and customer service can be a challenge for any e-commerce. Imagine a system that automates everything. With Recenstar's AI, feedback requests and customer interactions are handled automatically. Increase your online visibility and consumer trust. The AI promptly responds to questions and solves problems in real-time, reducing operational costs and improving efficiency. With Recenstar, your e-commerce becomes leaner, more efficient, and customer-oriented!",
      "beforeImage": "/ecommerce1j.jpg",
      "afterImage": "/ecommerce2.jpg"
    },
    "hotels": {
      "title": "Hotels",
      "description": "Managing bookings and reviews is arduous for hotels and B&Bs. Imagine a 24/7 virtual assistant that handles everything. With Recenstar's AI, bookings and cancellations are automatic, error-free. The system requests feedback after each stay, increasing positive reviews and improving online reputation. This attracts more guests and makes them return. With Recenstar, your hotel never sleeps, offering an extraordinary experience and stress-free operations.",
      "beforeImage": "/hotel1.jpg",
      "afterImage": "/hotel2.jpg"
    }
  },
  "hero": {
    "title": "Master AI Before It Masters You",
    "description": "Train an AI agent as if it were your employee, provide it with all your business information. The rest is pure magic. Want to bet?",
    "ctaButton": "Try for Free Now"
  },
  "errors": {
    "videoPlayback": "Error during video playback:",
    "video": "Video error:",
    "videoUnsupported": "Your browser does not support the video tag."
  },
  "featuresSection": {
    "mainTitle": "AI Will Replace Many Jobs",
    "subTitle": "And you can benefit from this by consciously introducing AI into your processes. How? Let's find out together",
    "ctaButton": "Try Now for Free",
    "videoUnsupported": "Your browser does not support the video tag.",
    "feature1": {
      "title": "Automate the Booking Process",
      "description": "Your AI agents can receive and make calls, automatically check your schedule, and make appointments for you, 24/7. They will manage bookings and cancellations just like you would, but without ever sleeping. Your schedule will always be under control with zero stress for you."
    },
    "feature2": {
      "title": "Optimize Feedback Requests. Work Smarter, Not Harder.",
      "description": "Asking customers to leave a review on Google, TripAdvisor, and other platforms can be frustrating and embarrassing. With our AI agent, you won't have to worry about this anymore. Artificial intelligence will take care of requesting reviews for you, increasing your online visibility and, consequently, your revenue."
    },
    "feature3": {
      "title": "Free Yourself from Booking Platform Slavery",
      "description": "Booking platforms are great for finding new customers, but then it's up to you to make them feel special and loyal. Our software, thanks to artificial intelligence, will help you transform those customers into devoted fans, fully leveraging the potential of your customer base. Make the company's customers Your Asset. For real, though."
    },
    "feature4": {
      "title": "Communicate Directly with Your Customers on WhatsApp and Facebook (or Your App)",
      "description": "Have you ever heard of CRM and all-in-one marketing tools? With our AI, you can do much more. You'll be able to communicate with your customers 24 hours a day, listening to their needs and answering their questions on WhatsApp, Facebook, and other platforms. This way, you'll never miss an opportunity to listen to, engage, and satisfy your customers."
    }
  },
  "reasons": {
    "title": "Revolutionize Your Business",
    "subtitle": "Discover how RecenStar can transform your business",
    "ctaButton": "Try Now for Free"
  },
  "faq": {
    "title": "FAQ",
    "subtitle": "Still doubtful whether your business should adopt artificial intelligence?",
    "q1": "What is Recenstar? At what stage are you?",
    "a1": "Recenstar is an innovative service for automating reviews and bookings through AI and much more. It's a platform that aims to boost your business thanks to AI. We are currently in the demo phase, so what you'll see is an MVP, representing only 1% of what Recenstar will become. This appetizer will show you how AI can improve your business. If we gather enough interest during this demo, we'll proceed with a small fundraising to develop the full platform and form the dream team! If you like the idea, sign up and spread the word, it's free!",
    "q2": "What are the advantages of automating bookings and reviews with Recenstar's AI?",
    "a2": "Recenstar's AI automatically manages bookings and reviews 24/7, eliminating errors and freeing up time for you to focus on your core business. It increases online visibility and improves customer satisfaction by simplifying daily operations.",
    "q3": "How does the positive feedback system work on platforms like Google and TripAdvisor?",
    "a3": "Recenstar uses AI to encourage satisfied customers to leave positive reviews on Google and TripAdvisor. This automated process increases your business visibility and improves your online reputation.",
    "q4": "How can Recenstar improve my business's online review management?",
    "a4": "Recenstar centralizes and automates review collection, quickly responding to customer feedback and improving your online presence. This reduces the time and effort needed to manage reviews manually.",
    "q5": "How much will Recenstar cost?",
    "a5": "During the demo phase, Recenstar is free, you'll have credits to use various services. You can also earn more by making Recenstar known to your friends. In the future, we'd like to avoid charging monthly subscriptions, we're all tired of subscriptions to be honest, so we'll work to create something consumption-based: you only pay for what you use.",
    "q6": "Is Recenstar compatible with my business's current management systems?",
    "a6": "Recenstar is designed to integrate with existing management systems, making implementation easy without disrupting current operations. We provide support to ensure a smooth transition."
  },
  "footer": {
    "slogan": "Master AI before competitors master you, thanks to it.",
    "features": "Features",
    "benefits": "Benefits",
    "faq": "FAQ",
    "testimonials": "Testimonials",
    "contacts": "Contacts",
    "email": "Email",
    "copyright": "© {{year}} Recenstar. All rights reserved.",
    "privacyPolicy": "Privacy Policies"
  },
  "areaPersonale": {
    "title": "Personal Area",
    "accountDetails": "Account Details",
    "name": "Name",
    "surname": "Surname",
    "email": "Email",
    "verifiedPhoneNumber": "Verified Phone Number",
    "notVerified": "Not Verified",
    "recenstarCredit": "Recenstar Credit",
    "contactSupport": "Contact info@recenstar.com",
    "deletePhoneNumber": "Delete Phone Number",
    "deletePhoneNumberTip": "You have {{count}} more chances to delete the number, use them wisely!",
    "verifyPhoneNumber": "Verify Phone Number",
    "chatbot": "Chatbot",
    "chatbotConfigDescription": "Customize your AI Agent by modifying its characteristics and behavior.",
    "testChatbot":"Try your Chatbot",
    "testChatbotDescription":"Once configured, you can simulate a sales or booking experience! What are you waiting for?",
    "hideOptions": "Hide Options",
    "configureAgent": "Configure Agent",
    "configureChatbot": "Configure Chatbot",
    "yourChatbot": "Your Chatbot",
    "voicebot": "Voicebot",
    "configureVoicebot": "Configure VoiceBot",
    "voicebotDescription": "Revolutionize your customers' experience with our advanced Voicebot! Manage calls, bookings, and assistance easily and quickly.",
    "analytics": "Analytics",
    "hideAnalytics": "Hide Analytics",
    "viewAnalytics": "View Analytics",
    "errors": {
      "deletePhoneNumber": "Error deleting phone number",
      "updateName": "Error updating name. Please try again.",
      "updateSurname": "Error updating surname. Please try again!."
    }
  },
  "cookieConsent": {
    "message": "We use cookies to enhance your experience on Recenstar. By accepting, you consent to the use of all cookies.",
    "accept": "Accept",
    "reject": "Reject",
    "close": "Close"
  },
   "privacyPolicy": {
    "title": "Privacy Policy and Data Management",
    "introduction": {
      "title": "1. Introduction",
      "content": "Hey there! Welcome to Recenstar's Privacy Policy. We know reading this stuff is boring, but it's important! We're committed to protecting your privacy and managing your personal data in a transparent and secure way, in compliance with GDPR (that complicated European regulation that keeps us all safe)."
    },
    "dataCollection": {
      "title": "2. Data Collection and Use",
      "content": "We collect and use your personal data only with your explicit consent and for specific purposes. No funny business! Here's what we do with your data:<ul><li>Improve our services (so you can have an ever more wow experience!)</li><li>Personalize your experience (because you're unique, and we know it)</li><li>Communicate with you about our service (promise, no spam!)</li><li>Marketing purposes, but only if you give us permission (we're not stalkers, you know)</li></ul>"
    },
    "dataTypes": {
      "title": "3. Types of Data Collected",
      "content": "Here's the personal data we might collect (don't panic, it's basic stuff):<ul><li>Name and surname (unless your name is Darth Vader)</li><li>Email address (no, we don't accept carrier pigeons)</li><li>Phone number (don't worry, we won't call you at 3 AM)</li><li>Information about your use of our service (like whether you prefer kittens or puppies... just kidding!)</li></ul>"
    },
    "consent": {
      "title": "4. Consent",
      "content": "We require your explicit consent for the collection and use of your personal data. You can change your mind anytime, we're democratic!"
    },
    "userRights": {
      "title": "5. User Rights",
      "content": "In accordance with GDPR, you have a ton of rights. You're basically privacy superheroes:<ul><li>Right to access your personal data (like 007)</li><li>Right to rectify inaccurate data (oops, we got it wrong? We'll fix it right away!)</li><li>Right to erasure ('right to be forgotten', for when you want to disappear like a ninja)</li><li>Right to restriction of processing (put a brake on our creativity)</li><li>Right to data portability (if you want to pack your digital bags)</li><li>Right to object to processing (when you want to tell us 'Enough!')</li></ul>"
    },
    "optOut": {
      "title": "6. Opt-out and Data Deletion",
      "content": "If you want to delete your data or say 'So long, and thanks for all the fish' to our service, just send an email to info@recenstar.com. We'll be sad, but we'll respect your decision."
    },
    "dataProtection": {
      "title": "7. Data Protection",
      "content": "We protect your data like it's our rare trading card collection. We adopt super sophisticated technical and organizational security measures to keep your information safe."
    },
    "dataBreachNotification": {
      "title": "8. Data Breach Notification",
      "content": "If something ever happens to your data (knock on wood!), we'll inform you right away, faster than a viral tweet. Within 72 hours, to be precise."
    },
    "dpo": {
      "title": "9. Data Protection Officer (DPO)",
      "content": "Our Data Protection Officer is like a privacy superhero. You can contact them at dpo@recenstar.com for any questions. They don't have superpowers, but they do their best!"
    },
    "minorsData": {
      "title": "10. Use of Minors' Data",
      "content": "Our services are for those over 16. If you're younger, please go play Fortnite. If you're a parent and think your child has given us their data, contact us and we'll sort it out."
    },
    "internationalTransfers": {
      "title": "11. International Data Transfers",
      "content": "Sometimes your data might take a trip abroad (lucky them!). But don't worry, we protect them as if they were on vacation with us."
    },
    "dataRetention": {
      "title": "12. Data Retention",
      "content": "We keep your data only for as long as necessary. We're not data hoarders! After a while, we delete them or make them anonymous, so even we don't know whose they are anymore."
    },
    "cookies": {
      "title": "13. Cookies",
      "content": "We use cookies, but not the tasty kind (unfortunately). They're small files that help us improve your experience on the site. You can manage them from your browser, like they're video game settings."
    },
    "policyChanges": {
      "title": "14. Changes to Privacy Policy",
      "content": "We might update this policy from time to time. No, not because we're bored, but to always be at the top of our game! We'll let you know if we make any important changes, promise."
    },
    "contact": {
      "title": "15. Contact",
      "content": "For any questions about this policy or if you just want to chat about privacy (we're always here for you!), write to us at privacy@recenstar.com."
    },
    "finalConsent": {
      "title": "16. Consent",
      "content": "By using our services, you accept this privacy policy. If you don't agree, we're sorry, but you'll have to find another place to have fun with AI!"
    },
    "cta": "To delete your account or for any info about the processing of your data, contact info@recenstar.com"
    }
  }
  