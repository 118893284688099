import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ComparisonSlider from './ComparisonSlider';

const categories = [
  "restaurants",
  "medicalOffices",
  "beautyCenters",
  "gyms",
  "ecommerce",
  "hotels"
];

const Benefits = () => {
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState(categories[0]);

  const CategoryButton = ({ category, isActive, onClick }) => (
    <button
      className={`w-full sm:w-auto px-4 py-2 m-1 text-sm font-medium rounded-full transition-colors duration-300 ${
        isActive
          ? 'bg-secondary text-background font-bold'
          : 'bg-dark-gray text-primary hover:bg-opacity-75'
      }`}
      onClick={() => onClick(category)}
    >
      {t(`categories.${category}`)}
    </button>
  );

  const BenefitItem = ({ category }) => (
    <div className="flex flex-col lg:flex-row items-center p-4">
      <div className="lg:w-1/2 lg:pr-8 mb-4 lg:mb-0">
        <h3 className="text-2xl font-bold mb-2 text-primary">{t(`benefits.${category}.title`)}</h3>
        <p className="text-primary">{t(`benefits.${category}.description`)}</p>
      </div>
      <div className="w-full lg:w-1/2 h-64 lg:h-80">
        <ComparisonSlider
          beforeImage={t(`benefits.${category}.beforeImage`)}
          afterImage={t(`benefits.${category}.afterImage`)}
          beforeLabel={t('benefits.withoutRecenstar')}
          afterLabel={t('benefits.withRecenstar')}
        />
      </div>
    </div>
  );

  return (
    <section id="benefits" className="pt-4 pb-20 bg-background">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-primary mb-4">{t('benefits.mainTitle')}</h2>
        <p className="text-lg md:text-xl text-center text-primary mb-8">{t('benefits.subTitle')}</p>
        
        <div className="mb-8 grid grid-cols-2 sm:flex sm:flex-wrap sm:justify-center gap-2">
          {categories.map((category) => (
            <CategoryButton
              key={category}
              category={category}
              isActive={activeCategory === category}
              onClick={setActiveCategory}
            />
          ))}
        </div>
        
        <div className="bg-dark-gray rounded-lg p-4 md:p-6 shadow-lg">
          <BenefitItem category={activeCategory} />
        </div>
      </div>
    </section>
  );
};

export default Benefits;