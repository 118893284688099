{
  "common": {
    "welcome": "Benvenuto in Recenstar",
    "login": "Accedi",
    "register": "Registrati",
    "features": "Funzioni",
    "business": "Business",
    "magic": "Magia",
    "solutions": "Soluzioni",
    "tryDemoFree": "Prova la Demo Gratis",
    "loading": "Caricamento...",
    "backToHome": "Torna alla Home",
    "save": "Salva",
    "cancel": "Annulla",
    "edit": "Modifica",
    "logout": "Logout"
  },
  "categories": {
    "restaurants": "Ristoranti",
    "medicalOffices": "Studi Medici",
    "beautyCenters": "Centri Estetici",
    "gyms": "Palestre",
    "ecommerce": "E-commerce",
    "hotels": "Hotel"
  },
  "benefits": {
    "mainTitle": "AI Nel Tuo Business = Vantaggio Competitivo Incredibile",
    "subTitle": "Se credi che nel tuo Business l'AI non farà la differenza, ti sbagli di grosso. Vediamo qualche esempio.",
    "withoutRecenstar": "Senza Recenstar",
    "withRecenstar": "Con Recenstar",
    "restaurants": {
      "title": "Ristoranti",
      "description": "Immagina di non dover più gestire prenotazioni e recensioni. Con l'AI di Recenstar, tutto è automatico 24/7. Niente più corse dietro ai clienti per le recensioni: il nostro sistema lo fa per te, garantendo feedback positivi su Google e TripAdvisor. Questo aumenta la tua visibilità online e la soddisfazione dei clienti. L'AI semplifica le operazioni, eliminando errori e liberando tempo per concentrarti su ciò che ami: offrire un'esperienza culinaria indimenticabile. Con Recenstar, il tuo ristorante non dorme mai e il successo è garantito!",
      "beforeImage": "/ristorante1.jpeg",
      "afterImage": "/ristorante2.jpg"
    },
    "medicalOffices": {
      "title": "Studi Medici",
      "description": "Gestire prenotazioni e cancellazioni può essere un incubo per gli studi medici. Immagina un assistente che lavora 24/7 senza stancarsi: con l'AI di Recenstar, è possibile. Prenotazioni e cancellazioni gestite automaticamente, zero errori, massima efficienza. I pazienti prenotano e annullano facilmente, tutto scorre liscio. L'AI invia richieste di feedback dopo ogni visita, migliorando la tua reputazione online. Con Recenstar, ogni interazione è perfetta, ogni paziente è felice e il tuo studio funziona come un orologio svizzero. Andare dal medico non sarà più un incubo (ma non garantiamo).",
      "beforeImage": "/medico1.jpg",
      "afterImage": "/medico2.jpg"
    },
    "beautyCenters": {
      "title": "Centri Estetici",
      "description": "Chiedere recensioni nei centri estetici è imbarazzante e dispendioso. Con l'AI di Recenstar, non dovrai più preoccupartene. Il nostro sistema chiede feedback automaticamente, aumentando la tua visibilità online. Immagina un assistente che gestisce prenotazioni, invia promemoria e raccoglie recensioni, senza sforzo. Migliora l'efficienza e ti permette di concentrarti su ciò che ami: far sentire i clienti al meglio. Con Recenstar, ogni cliente si sentirà speciale e il tuo centro benessere brillerà come mai prima!",
      "beforeImage": "/spa1.jpeg",
      "afterImage": "/spa2.jpg"
    },
    "gyms": {
      "title": "Palestre",
      "description": "Gestire prenotazioni e comunicare con i membri può essere caotico. Immagina un sistema che automatizza tutto. Con l'AI di Recenstar, prenotazioni per lezioni e sessioni con personal trainer sono gestite senza sforzo. Comunica direttamente via WhatsApp e Facebook, rispondendo a domande e inviando promozioni. Questo aumenta l'engagement e la soddisfazione dei clienti, che si sentiranno ascoltati e valorizzati. Con Recenstar, la tua palestra diventa organizzata e accogliente, una comunità attiva e coinvolgente!",
      "beforeImage": "/palestra1.jpg",
      "afterImage": "/palestra2.jpg"
    },
    "ecommerce": {
      "title": "Ecommerce",
      "description": "Gestire recensioni e assistenza clienti può essere una sfida per qualsiasi ecommerce. Immagina un sistema che automatizza tutto. Con l'AI di Recenstar, le richieste di feedback e le interazioni con i clienti sono gestite automaticamente. Aumenta la tua visibilità online e la fiducia dei consumatori. L'AI risponde prontamente alle domande e risolve i problemi in tempo reale, riducendo i costi operativi e migliorando l'efficienza. Con Recenstar, il tuo ecommerce diventa più snello, efficiente e orientato al cliente!",
      "beforeImage": "/ecommerce1j.jpg",
      "afterImage": "/ecommerce2.jpg"
    },
    "hotels": {
      "title": "Hotel",
      "description": "Gestire prenotazioni e recensioni è arduo per hotel e B&B. Immagina un assistente virtuale 24/7 che gestisce tutto. Con l'AI di Recenstar, prenotazioni e cancellazioni sono automatiche, senza errori. Il sistema richiede feedback dopo ogni soggiorno, aumentando recensioni positive e migliorando la reputazione online. Questo attira più ospiti e li fa tornare. Con Recenstar, il tuo hotel non dorme mai, offrendo un'esperienza straordinaria e operazioni senza stress.",
      "beforeImage": "/hotel1.jpg",
      "afterImage": "/hotel2.jpg"
    }
  },
  "hero": {
    "title": "Domina L'AI Prima Dei Tuoi Competitors",
    "description": "Istruisci un agente AI come se fosse un tuo dipendente, forniscigli tutte le informazioni del tuo business. Il resto è pura magia. Scommettiamo?",
    "ctaButton": "Prova Gratis"
  },
  "errors": {
    "videoPlayback": "Errore durante la riproduzione del video:",
    "video": "Errore video:",
    "videoUnsupported": "Il tuo browser non supporta il tag video."
  },
  "featuresSection": {
    "mainTitle": "L'AI sostituirà tantissimi lavori",
    "subTitle": "E tu puoi beneficiare di ciò introducendo consapevolmente l'AI nei tuoi processi. In che modo? Scopriamolo insieme",
    "ctaButton": "Prova Ora Gratis",
    "videoUnsupported": "Il tuo browser non supporta il tag video.",
    "feature1": {
      "title": "Automatizza il Processo di Prenotazione",
      "description": "I tuoi agenti AI possono ricevere e fare chiamate, controllare automaticamente la tua agenda e prendere appuntamenti per te, 24/7. Gestiranno prenotazioni e cancellazioni come faresti tu, ma senza mai dormire. La tua agenda sarà sempre sotto controllo con zero stress per te."
    },
    "feature2": {
      "title": "Ottimizza la Richiesta di Feedback. Work Smarter, Not Harder.",
      "description": "Chiedere ai clienti di lasciare una recensione su Google, TripAdvisor, e altre piattaforme può essere frustrante e imbarazzante. Con il nostro agente AI, non dovrai più preoccuparti di questo. L'intelligenza artificiale si occuperà di richiedere le recensioni per te, aumentando la tua visibilità online e, di conseguenza, il tuo fatturato."
    },
    "feature3": {
      "title": "Liberati Dalla Schiavitù Delle Piattaforme di Booking",
      "description": "Le piattaforme di booking sono fantastiche per trovare nuovi clienti, ma poi tocca a te farli sentire speciali e fidelizzarli. Il nostro software, grazie all' intelligenza artificiale ti aiuterà a trasformare quei clienti in tuoi fan devoti, sfruttando appieno il potenziale della tua base clienti. Rendi i clienti della azienda Tuo Patrimonio. Per davvero però."
    },
    "feature4": {
      "title": "Comunica Direttamente con i Tuoi Clienti su WhatsApp e Facebook (o la tua App)",
      "description": "Hai mai sentito parlare di CRM e di strumenti di marketing all-in-one? Con la nostra AI, potrai fare molto di più. Potrai comunicare con i tuoi clienti 24 ore su 24, ascoltando le loro esigenze e rispondendo alle loro domande su WhatsApp, Facebook e altre piattaforme. Così, non perderai mai un'opportunità di ascoltare, coinvolgere e soddisfare i tuoi clienti."
    }
  },
  "reasons": {
    "title": "Rivoluziona il Tuo Business",
    "subtitle": "Scopri come RecenStar può trasformare la tua attività",
    "ctaButton": "Prova Ora Gratis"
  },
  "faq": {
    "title": "FAQ",
    "subtitle": "Ancora dubbioso sul fatto che il tuo business debba adottare l'intelligenza artificiale?",
    "q1": "Cos'è Recenstar? A che punto siete?",
    "a1": "Recenstar è un servizio innovativo per l'automazione delle recensioni e delle prenotazioni attraverso l'AI e molto altro. E' una piattaforma che mira a mettere il boost alla tua azienda grazie all'AI. Attualmente siamo in fase demo, quindi quella che vedrai è un MVP, rappresentando solo l'1% di ciò che Recenstar diventerà. Questo antipasto ti mostrerà come l'AI può migliorare il tuo business. Se raccogliamo abbastanza interesse durante questa demo, procederemo con un piccolo fundraising per sviluppare la piattaforma completa e formare il dream team! Se ti piace l'idea, registrati e spargi la notizia, è gratuito!",
    "q2": "Quali sono i vantaggi dell'automazione delle prenotazioni e delle recensioni con l'AI di Recenstar?",
    "a2": "L'AI di Recenstar gestisce automaticamente le prenotazioni e le recensioni 24/7, eliminando errori e liberando tempo per concentrarti sul tuo core business. Aumenta la visibilità online e migliora la soddisfazione dei clienti, semplificando le operazioni quotidiane.",
    "q3": "Come funziona il sistema di feedback positivo su piattaforme come Google e TripAdvisor?",
    "a3": "Recenstar utilizza l'AI per incoraggiare i clienti soddisfatti a lasciare recensioni positive su Google e TripAdvisor. Questo processo automatizzato aumenta la visibilità del tuo business e migliora la tua reputazione online.",
    "q4": "Come può Recenstar migliorare la gestione delle recensioni online del mio business?",
    "a4": "Recenstar centralizza e automatizza la raccolta delle recensioni, rispondendo rapidamente ai feedback dei clienti e migliorando la tua presenza online. Questo riduce il tempo e lo sforzo necessari per gestire le recensioni manualmente.",
    "q5": "Quanto costerà Recenstar?",
    "a5": "Durante la fase demo, Recenstar è gratuito, avrai dei crediti per utilizzare i vari servizi. Tra l'altro ne puoi anche guadagnare altri facendo conoscere Recenstar ai tuoi amici. In futuro vorremmo evitare di far pagare abbonamenti mensili, siamo tutti stanchi degli abbonamenti ad essere sinceri, quindi lavoreremo per creare qualcosa a consumo: paghi solo quanto consumi.",
    "q6": "Recenstar è compatibile con i sistemi di gestione attuali del mio business?",
    "a6": "Recenstar è progettato per integrarsi con i sistemi di gestione esistenti, rendendo facile l'implementazione senza interrompere le operazioni correnti. Forniamo supporto per assicurare una transizione senza problemi."
  },
  "footer": {
    "slogan": "Domina l'AI prima che i competitors dominino te, grazie ad essa.",
    "features": "Funzionalità",
    "benefits": "Vantaggi",
    "faq": "FAQ",
    "testimonials": "Testimonianze",
    "contacts": "Contatti",
    "email": "Email",
    "copyright": "© {{year}} Recenstar. Tutti i diritti riservati.",
    "privacyPolicy": "Informativa sulla Privacy"
  },
  "areaPersonale": {
    "title": "Area Personale",
    "accountDetails": "Dettagli Account",
    "name": "Nome",
    "surname": "Cognome",
    "email": "Email",
    "verifiedPhoneNumber": "Numero di telefono verificato",
    "notVerified": "Non verificato",
    "recenstarCredit": "Credito Recenstar",
    "contactSupport": "Contattare info@recenstar.com",
    "deletePhoneNumber": "Cancella numero di telefono",
    "deletePhoneNumberTip": "Hai ancora a disposizione {{count}} volte per cancellare il numero, usale bene!",
    "verifyPhoneNumber": "Verifica numero di telefono",
    "chatbot": "Chatbot",
    "chatbotConfigDescription": "Personalizza il tuo Agente AI modificando le sue caratteristiche e il suo comportamento.",
    "testChatbot": "Testa il Chatbot",
    "testChatbotDescription": "Una volta configurato, puoi simulare un'esperienza di vendita o di prenotazione! Che aspetti?",
    "hideOptions": "Nascondi Opzioni",
    "configureAgent": "Configura Agente",
    "configureChatbot": "Configura Chatbot",
    "yourChatbot": "Il tuo Chatbot",
    "voicebot": "Voicebot",
    "configureVoicebot": "Configura VoiceBot",
    "voicebotDescription": "Rivoluziona l'esperienza dei tuoi clienti con il nostro Voicebot avanzato! Gestisci chiamate, prenotazioni e assistenza in modo semplice e veloce.",
    "analytics": "Analytics",
    "hideAnalytics": "Nascondi Analytics",
    "viewAnalytics": "Visualizza Analytics",
    "errors": {
      "deletePhoneNumber": "Errore nella cancellazione del numero di telefono",
      "updateName": "Errore nell'aggiornamento del nome. Riprova.",
      "updateSurname": "Errore nell'aggiornamento del cognome. Riprova Ora."
    }
  },
  "cookieConsent": {
    "message": "Utilizziamo i cookie per migliorare la tua esperienza su Recenstar. Accettando, consenti l'uso di tutti i cookie.",
    "accept": "Accetta",
    "reject": "Rifiuta",
    "close": "Chiudi"
  },
    "privacyPolicy": {
    "title": "Informativa sulla Privacy e Gestione dei Dati",
    "introduction": {
      "title": "1. Introduzione",
      "content": "Ehi ciao! Benvenuti nell'Informativa sulla Privacy di Recenstar. Sappiamo che leggere queste cose è noioso, ma è importante! Ci impegniamo a proteggere la vostra privacy e a gestire i vostri dati personali in modo trasparente e sicuro, in conformità con il GDPR (quel regolamento europeo un po' complicato, ma che ci tiene tutti al sicuro)."
    },
    "dataCollection": {
      "title": "2. Raccolta e Utilizzo dei Dati",
      "content": "Raccogliamo e utilizziamo i vostri dati personali solo con il vostro consenso esplicito e per scopi specifici. Niente scherzi! Ecco cosa facciamo con i vostri dati:<ul><li>Migliorare i nostri servizi (così potete avere un'esperienza sempre più wow!)</li><li>Personalizzare la vostra esperienza (perché siete unici, e lo sappiamo)</li><li>Comunicare con voi riguardo al nostro servizio (promesso, niente spam!)</li><li>Scopi di marketing, ma solo se ci date il permesso (non siamo mica degli stalker)</li></ul>"
    },
    "dataTypes": {
      "title": "3. Tipi di Dati Raccolti",
      "content": "Ecco i dati personali che potremmo raccogliere (non spaventatevi, è roba basic):<ul><li>Nome e cognome (a meno che non vi chiamiate Darth Vader)</li><li>Indirizzo email (no, non accettiamo piccioni viaggiatori)</li><li>Numero di telefono (tranquilli, non vi chiameremo alle 3 di notte)</li><li>Informazioni sul vostro utilizzo del nostro servizio (tipo se preferite i gattini o i cagnolini... scherzo!)</li></ul>"
    },
    "consent": {
      "title": "4. Consenso",
      "content": "Richiediamo il vostro consenso esplicito per la raccolta e l'utilizzo dei vostri dati personali. Potete cambiare idea in qualsiasi momento, siamo democratici!"
    },
    "userRights": {
      "title": "5. Diritti degli Utenti",
      "content": "In conformità con il GDPR, avete un sacco di diritti. Siete praticamente dei supereroi della privacy:<ul><li>Diritto di accesso ai vostri dati personali (tipo 007)</li><li>Diritto di rettifica dei dati inaccurati (ops, abbiamo sbagliato? Correggiamo subito!)</li><li>Diritto alla cancellazione dei dati ('diritto all'oblio', per quando volete sparire come un ninja)</li><li>Diritto alla limitazione del trattamento (mettete un freno alla nostra creatività)</li><li>Diritto alla portabilità dei dati (se volete fare le valigie digitali)</li><li>Diritto di opposizione al trattamento (quando volete dirci 'Basta così!')</li></ul>"
    },
    "optOut": {
      "title": "6. Opt-out e Cancellazione dei Dati",
      "content": "Se volete cancellare i vostri dati o dire 'Arrivederci e grazie per tutti i pesci' al nostro servizio, mandateci una mail a info@recenstar.com. Saremo tristi, ma rispetteremo la vostra decisione."
    },
    "dataProtection": {
      "title": "7. Protezione dei Dati",
      "content": "Proteggiamo i vostri dati come se fossero la nostra collezione di figurine rare. Adottiamo misure di sicurezza tecniche e organizzative super sofisticate per tenere al sicuro le vostre informazioni."
    },
    "dataBreachNotification": {
      "title": "8. Notifica di Violazione dei Dati",
      "content": "Se mai dovesse succedere qualcosa ai vostri dati (tocchiamo ferro!), vi informeremo subito, più veloci di un tweet virale. Entro 72 ore, per la precisione."
    },
    "dpo": {
      "title": "9. Responsabile della Protezione dei Dati (DPO)",
      "content": "Il nostro Responsabile della Protezione dei Dati è come un supereroe della privacy. Potete contattarlo a dpo@recenstar.com per qualsiasi domanda. Non ha i superpoteri, ma fa del suo meglio!"
    },
    "minorsData": {
      "title": "10. Uso di Dati dei Minori",
      "content": "I nostri servizi sono per i maggiori di 16 anni. Se sei più giovane, per favore vai a giocare a Fortnite. Se sei un genitore e pensi che tuo figlio ci abbia dato i suoi dati, contattaci e sistemeremo tutto."
    },
    "internationalTransfers": {
      "title": "11. Trasferimenti Internazionali di Dati",
      "content": "A volte i vostri dati potrebbero fare un viaggio all'estero (beati loro!). Ma non preoccupatevi, li proteggiamo come se fossero in vacanza con noi."
    },
    "dataRetention": {
      "title": "12. Conservazione dei Dati",
      "content": "Conserviamo i vostri dati solo per il tempo necessario. Non siamo accumulatori seriali di dati! Dopo un po', li cancelliamo o li rendiamo anonimi, così nemmeno noi sappiamo più di chi sono."
    },
    "cookies": {
      "title": "13. Cookie",
      "content": "Usiamo i cookie, ma non quelli buoni da mangiare (purtroppo). Sono piccoli file che ci aiutano a migliorare la vostra esperienza sul sito. Potete gestirli dal vostro browser, come se fossero le impostazioni di un videogioco."
    },
    "policyChanges": {
      "title": "14. Modifiche alla Politica sulla Privacy",
      "content": "Ogni tanto potremmo aggiornare questa politica. No, non perché ci annoiamo, ma per essere sempre al top! Vi avviseremo se facciamo cambiamenti importanti, promesso."
    },
    "contact": {
      "title": "15. Contatti",
      "content": "Per qualsiasi domanda su questa politica o se volete solo fare due chiacchiere sulla privacy (siamo sempre qui per voi!), scriveteci a privacy@recenstar.com."
    },
    "finalConsent": {
      "title": "16. Consenso",
      "content": "Usando i nostri servizi, accettate questa politica sulla privacy. Se non siete d'accordo, ci dispiace, ma dovrete trovare un altro posto dove divertirvi con l'AI!"
    },
    "cta": "Per cancellare il proprio account o per qualsiasi info relativa al trattamento dei propri dati, mandate una mail a info@recenstar.com. Promettiamo di rispondere più velocemente di quanto ci metta un gatto a conquistare Internet!"
  }
}