import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  BarChart, Bar, PieChart, Pie, Cell
} from 'recharts';

const DashboardAnalytics = () => {
  const [analytics, setAnalytics] = useState({
    sales: [],
    topProducts: [],
    costComparison: {},
    conversionRate: 0,
    totalSales: 0,
    totalOrders: 0,
    totalInteractions: 0
  });
  const [customSaleAmount, setCustomSaleAmount] = useState(100);
  const [customPlatformFee, setCustomPlatformFee] = useState(15);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const fetchAnalytics = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/analytics`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setAnalytics(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Errore nel recupero delle analitiche:', error);
      setError('Si è verificato un errore nel caricamento dei dati');
      setIsLoading(false);
    }
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

  const calculateSavings = (amount, fee) => {
    const traditionalCost = amount * (fee / 100);
    const chatbotCost = 0.002;
    return (traditionalCost - chatbotCost).toFixed(2);
  };

  // Calcoli sicuri
  const chatbotCost = (analytics.totalOrders || 0) * 0.05;
  const traditionalPlatformsCost = (analytics.totalSales || 0) * 0.15;
  const totalSavings = traditionalPlatformsCost - chatbotCost;

  const overviewData = [
    { name: 'Fatturato', value: analytics.totalSales || 0 },
    { name: 'Fees Piattaforme', value: traditionalPlatformsCost },
    { name: 'Risparmio', value: totalSavings }
  ];

  if (isLoading) {
    return <div className="text-white">Caricamento in corso...</div>;
  }

  if (error) {
    return <div className="text-white">Errore: {error}</div>;
  }

  return (
    <div className="dashboard-analytics text-white">
      <h2 className="text-2xl font-bold mb-4">Dashboard Analytics</h2>

      {analytics.totalSales !== undefined ? (
        <>
          {/* Sezione "Lo Sapevi?" */}
          <div className="p-4 rounded-lg shadow mb-4" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
            <h3 className="text-lg font-semibold mb-2">Lo Sapevi?</h3>
            <p className="text-md">
              Se avessi gestito tutte le tue vendite (€{(analytics.totalSales || 0).toFixed(2)}) tramite piattaforme tradizionali, avresti speso €{traditionalPlatformsCost.toFixed(2)} in commissioni!
            </p>
            <p className="text-md mt-2">
              Con il nostro chatbot, hai speso solo €{chatbotCost.toFixed(2)}. È come se avessi un super dipendente che lavora 24/7 per meno di un centesimo a interazione!
            </p>
            <p className="text-lg font-bold mt-4">
              In altre parole, hai risparmiato abbastanza per {Math.floor(totalSavings / 0.9)} caffè! ☕️
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Nuovo grafico: Panoramica Finanziaria */}
            <div className="p-4 rounded-lg shadow" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
              <h3 className="text-lg font-semibold mb-2">Panoramica Finanziaria</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={overviewData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                  <XAxis dataKey="name" stroke="#fff" />
                  <YAxis stroke="#fff" />
                  <Tooltip contentStyle={{ backgroundColor: '#333', borderColor: '#444' }} />
                  <Legend wrapperStyle={{ color: '#fff' }} />
                  <Bar dataKey="value" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            {/* Grafico prodotti/servizi più venduti */}
            <div className="p-4 rounded-lg shadow" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
              <h3 className="text-lg font-semibold mb-2">Prodotti/Servizi più venduti</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={analytics.topProducts || []}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                  <XAxis dataKey="name" stroke="#fff" />
                  <YAxis stroke="#fff" />
                  <Tooltip contentStyle={{ backgroundColor: '#333', borderColor: '#444' }} />
                  <Legend wrapperStyle={{ color: '#fff' }} />
                  <Bar dataKey="quantity" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            {/* Analisi Comparativa dei Costi */}
            <div className="p-4 rounded-lg shadow" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
              <h3 className="text-lg font-semibold mb-2">Analisi Comparativa dei Costi</h3>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={[
                      { name: 'Costo Chatbot', value: chatbotCost },
                      { name: 'Costo Piattaforme Tradizionali', value: traditionalPlatformsCost }
                    ]}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {overviewData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip contentStyle={{ backgroundColor: '#333', borderColor: '#444' }} />
                  <Legend wrapperStyle={{ color: '#fff' }} />
                </PieChart>
              </ResponsiveContainer>
              <div className="mt-4">
                <p className="text-xl font-bold">Risparmio Totale: €{totalSavings.toFixed(2)}</p>
                <p className="text-sm text-gray-300">
                  Spesa effettiva: €{chatbotCost.toFixed(2)} | Spesa su piattaforme tradizionali: €{traditionalPlatformsCost.toFixed(2)}
                </p>
                <p className="text-sm text-gray-300">
                  Hai risparmiato il {((1 - (chatbotCost / traditionalPlatformsCost)) * 100).toFixed(2)}% rispetto alle piattaforme tradizionali!
                </p>
              </div>
            </div>

            {/* Calcolatore di Risparmio Interattivo */}
            <div className="p-4 rounded-lg shadow" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
              <h3 className="text-lg font-semibold mb-2">Calcolatore di Risparmio Interattivo</h3>
              <div className="flex space-x-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300">Importo Vendita (€)</label>
                  <input
                    type="number"
                    value={customSaleAmount}
                    onChange={(e) => setCustomSaleAmount(Number(e.target.value))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-black"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300">Fee Piattaforma Tradizionale (%)</label>
                  <input
                    type="number"
                    value={customPlatformFee}
                    onChange={(e) => setCustomPlatformFee(Number(e.target.value))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-black"
                  />
                </div>
              </div>
              <div className="mt-4">
                <p className="text-xl font-bold">
                  Risparmio Stimato: €{calculateSavings(customSaleAmount, customPlatformFee)}
                </p>
                <p className="text-sm text-gray-300">
                  Su una vendita di €{customSaleAmount}, risparmieresti il {((1 - (0.002 / (customSaleAmount * (customPlatformFee / 100)))) * 100).toFixed(2)}% usando il nostro chatbot!
                </p>
              </div>

              <div className="mt-4">
                <h4 className="text-lg font-semibold">Statistiche Aggiuntive</h4>
                <p>Tasso di Conversione: {(analytics.conversionRate || 0).toFixed(2)}%</p>
                <p>Totale Ordini: {analytics.totalOrders || 0}</p>
                <p>Risparmio Attuale: €{totalSavings.toFixed(2)}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>Nessun dato disponibile al momento.</p>
      )}
    </div>
  );
};

export default DashboardAnalytics;