import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ParticleBackground from './ParticleBackground';

const API_URL = 'https://recenstar-server.onrender.com'; // Sostituisci con l'URL corretto del tuo backend

const businessTypes = [
  'Ristorante', 'Palestra', 'Dentista', 'Centro estetico', 'Hotel', 'Ecommerce'
];

const businessCharacteristics = {
  'Ristorante': `Sei un assistente virtuale AI che lavora al ristorante NomeAzienda. Il tuo nome è NomeUtente. Il tuo compito è aiutare i clienti in modo divertente e coinvolgente con le loro ordinazioni e prenotazioni presso NomeAzienda. Simula di essere un dipendente del ristorante, confermando le ordinazioni, gli orari e rispondendo alle domande sui menu. 
Quando qualcuno completa un ordine per consegna a domicilio, chiedi cortesemente il loro indirizzo personale e numero di telefono. Se desiderano prenotare un tavolo, chiedi quante persone saranno presenti, se preferiscono mangiare all'interno o all'esterno, il nome per la prenotazione e il numero di telefono. Sii sempre educato, gentile e usa emoji per rendere l'interazione più piacevole. Puoi fornire dettagli sui piatti del menu e assistenza riguardo a qualsiasi altra richiesta.
Ricorda: devi essere divertente, ma anche professionale e rispettoso.`,
  'Palestra': `Sei un assistente vocale AI che lavora per la palestra NomeAzienda. Il tuo nome è NomeUtente. Il tuo compito è aiutare i clienti con le iscrizioni, prenotazioni di corsi e fornire informazioni sui servizi. Usa un tono energico e motivante.`,
  'Dentista': `Sei un assistente vocale AI che lavora per lo studio dentistico NomeAzienda. Il tuo nome è NomeUtente. Il tuo compito è aiutare i pazienti con le prenotazioni e fornire informazioni sui trattamenti. Parla in modo rassicurante e professionale.`,
  'Centro estetico': `Sei un assistente vocale AI che lavora per il centro estetico NomeAzienda. Il tuo nome è NomeUtente. Il tuo compito è aiutare i clienti con le prenotazioni e fornire informazioni sui trattamenti. Usa un tono rilassante e accogliente.`,
  'Hotel': `Sei un assistente vocale AI che lavora per l'hotel NomeAzienda. Il tuo nome è NomeUtente. Il tuo compito è aiutare gli ospiti con le prenotazioni e fornire informazioni sui servizi dell'hotel. Parla in modo cordiale e professionale.`,
  'Ecommerce': `Sei un assistente vocale AI che lavora per il Ecommerce NomeAzienda. Il tuo nome è NomeUtente. Il tuo compito è aiutare i clienti con informazioni sui prodotti e sugli ordini. Usa un tono alla moda e accogliente.`
};

const VoicebotConfig = () => {
  const [config, setConfig] = useState({
    tipologiaAzienda: '',
    nomeAzienda: '',
    nomeUtente: '',
    infoAzienda: '',
    caratteristicheAgente: '',
    lingua: 'italiano',
    numeroTelefono: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [notification, setNotification] = useState({ show: false, message: '', type: 'success' });
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const [configResponse, userResponse] = await Promise.all([
          axios.get(`${API_URL}/api/voicebot/get-config`, {
            headers: { 'Authorization': `Bearer ${token}` }
          }),
          axios.get(`${API_URL}/api/me`, {
            headers: { 'Authorization': `Bearer ${token}` }
          })
        ]);
        
        const userData = userResponse.data.user;
        const verifiedPhone = userData.verifiedPhoneNumber;
        
        if (configResponse.data) {
          setConfig(prevConfig => ({
            ...prevConfig,
            ...configResponse.data,
            numeroTelefono: verifiedPhone || configResponse.data.numeroTelefono || '',
          }));
        } else {
          // Se non c'è una configurazione esistente, imposta solo il numero di telefono
          setConfig(prevConfig => ({
            ...prevConfig,
            numeroTelefono: verifiedPhone || '',
          }));
        }
        setUser(userData);
      } catch (err) {
        console.error('Errore nel recupero della configurazione:', err);
        if (err.response && err.response.status === 403) {
          navigate('/verify-phone');
        } else {
          setError('Benvenuto! Inizia a configurare il tuo voicebot.');
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchConfig();
  }, [navigate]);

  useEffect(() => {
    if (config.tipologiaAzienda && config.nomeAzienda && config.nomeUtente) {
      setConfig(prevConfig => ({
        ...prevConfig,
        caratteristicheAgente: businessCharacteristics[config.tipologiaAzienda]
          .replace(/NomeAzienda/g, prevConfig.nomeAzienda)
          .replace(/NomeUtente/g, prevConfig.nomeUtente)
      }));
    }
  }, [config.tipologiaAzienda, config.nomeAzienda, config.nomeUtente]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfig(prevConfig => ({ ...prevConfig, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
  
    try {
      const token = localStorage.getItem('token');
      const dataToSend = {
        tipologiaAzienda: config.tipologiaAzienda,
        nomeAzienda: config.nomeAzienda,
        nomeUtente: config.nomeUtente,
        infoAzienda: config.infoAzienda,
        caratteristicheAgente: config.caratteristicheAgente,
        lingua: config.lingua
      };
  
      const response = await axios.post(`${API_URL}/api/voicebot/save-config`, dataToSend, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      setNotification({ show: true, message: 'Configurazione salvata con successo!', type: 'success' });
      setTimeout(() => setNotification({ show: false, message: '', type: 'success' }), 3000);
    } catch (err) {
      console.error('Errore nel salvataggio della configurazione:', err);
      setNotification({ show: true, message: 'Errore nel salvataggio della configurazione. Riprova.', type: 'error' });
      setTimeout(() => setNotification({ show: false, message: '', type: 'error' }), 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleStartCall = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/api/voicebot/start-call`, {}, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setNotification({ show: true, message: 'Chiamata iniziata con successo!', type: 'success' });
      setTimeout(() => setNotification({ show: false, message: '', type: 'success' }), 3000);
      console.log('Risposta dal server:', response.data);
    } catch (err) {
      console.error('Errore nell\'avvio della chiamata:', err);
      let errorMessage = 'Errore nell\'avvio della chiamata. ';
      if (err.response) {
        errorMessage += err.response.data.error || err.response.statusText;
      } else if (err.request) {
        errorMessage += 'Nessuna risposta dal server.';
      } else {
        errorMessage += err.message;
      }
      setNotification({ show: true, message: errorMessage, type: 'error' });
      setTimeout(() => setNotification({ show: false, message: '', type: 'error' }), 3000);
    }
  };

  if (loading) {
    return <div className="text-center mt-8">Caricamento...</div>;
  }

  return (
    <div className="relative min-h-screen bg-gray-900 text-white p-8">
      <ParticleBackground />
      <div className="relative z-10 max-w-4xl mx-auto">
        <h1 className="text-5xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-500 to-pink-500">
          Configurazione Voicebot
        </h1>
        <div className="bg-gray-800 p-8 rounded-lg shadow-xl">
          {error && <div className="bg-yellow-600 text-white p-3 rounded mb-4">{error}</div>}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="tipologiaAzienda" className="block mb-2">Tipologia Azienda</label>
              <select
                id="tipologiaAzienda"
                name="tipologiaAzienda"
                value={config.tipologiaAzienda}
                onChange={handleChange}
                className="w-full p-2 bg-gray-700 rounded text-white"
                required
              >
                <option value="">Seleziona una tipologia</option>
                {businessTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="nomeAzienda" className="block mb-2">Nome Azienda</label>
              <input
                type="text"
                id="nomeAzienda"
                name="nomeAzienda"
                value={config.nomeAzienda}
                onChange={handleChange}
                className="w-full p-2 bg-gray-700 rounded text-white"
                required
              />
            </div>
            <div>
              <label htmlFor="nomeUtente" className="block mb-2">Il tuo nome</label>
              <input
                type="text"
                id="nomeUtente"
                name="nomeUtente"
                value={config.nomeUtente}
                onChange={handleChange}
                className="w-full p-2 bg-gray-700 rounded text-white"
                required
              />
            </div>
            <div>
              <label htmlFor="infoAzienda" className="block mb-2">Informazioni Azienda (max 300 caratteri)</label>
              <textarea
                id="infoAzienda"
                name="infoAzienda"
                value={config.infoAzienda}
                onChange={handleChange}
                className="w-full p-2 bg-gray-700 rounded text-white"
                rows="4"
                maxLength="300"
                required
              />
            </div>
            <div>
              <label htmlFor="caratteristicheAgente" className="block mb-2">Caratteristiche Agente</label>
              <textarea
                id="caratteristicheAgente"
                name="caratteristicheAgente"
                value={config.caratteristicheAgente}
                className="w-full p-2 bg-gray-700 rounded text-white opacity-75"
                rows="4"
                readOnly
              />
            </div>
            <div>
              <label htmlFor="lingua" className="block mb-2">Lingua</label>
              <select
                id="lingua"
                name="lingua"
                value={config.lingua}
                onChange={handleChange}
                className="w-full p-2 bg-gray-700 rounded text-white"
                required
              >
                <option value="italiano">Italiano</option>
                <option value="inglese">Inglese</option>
              </select>
            </div>
            <div>
              <label htmlFor="numeroTelefono" className="block mb-2">Il tuo numero di telefono verificato</label>
              <input
                type="tel"
                id="numeroTelefono"
                name="numeroTelefono"
                value={config.numeroTelefono}
                readOnly
                className="w-full p-2 bg-gray-700 rounded text-white opacity-75"
              />
              {user && user.verifiedPhoneNumber && (
                <button 
                  onClick={() => navigate('/area-personale')}
                  className="mt-2 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
                >
                  Gestisci numero di telefono
                </button>
              )}
            </div>
            <button type="submit" className="w-full bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition duration-300">
              Salva Configurazione
            </button>
          </form>
          <button 
            onClick={handleStartCall} 
            className="w-full mt-6 bg-green-600 text-white px-4 py-3 rounded text-lg font-bold hover:bg-green-700 transition duration-300"
          >
            Ricevi Chiamata
          </button>
        </div>
        <div className="mt-8 text-center">
          <Link to="/area-personale" className="text-indigo-400 hover:underline">
            Torna all'Area Personale
          </Link>
        </div>
        {notification.show && (
          <div className={`fixed top-4 right-4 p-4 rounded-md shadow-lg ${
            notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
          } text-white`}>
            {notification.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default VoicebotConfig;