import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { inject as injectAnalytics, track } from '@vercel/analytics';
import { injectSpeedInsights } from '@vercel/speed-insights';
import ProtectedAdminRoute from './components/admin/ProtectedAdminRoute';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Features from './components/Features';
import Benefits from './components/Benefits';
import Reasons from './components/Reasons';
import Footer from './components/Footer';
import LoginPage from './components/LoginPage';
import AreaPersonale from './components/AreaPersonale';
import ChatBotPage from './components/ChatBotPage';
import ChatbotConfig from './components/ChatbotConfig';
import FAQ from './components/FAQ';
import VerifyMagicLink from './components/VerifyMagicLink';
import VoicebotConfig from './components/VoicebotConfig';
import VerifyPhonePage from './components/VerifyPhonePage';
import ChatbotAnalytics from './components/ChatbotAnalytics';
import VoiceBotAnalytics from './components/VoiceBotAnalytics';
import LogTransazioni from './components/LogTransazioni';
import CookieConsent from './components/CookieConsent';
import PrivacyPolicy from './components/PrivacyPolicy';

// Importa i componenti per il pannello admin
import AdminLogin from './components/admin/AdminLogin';
import AdminDashboard from './components/admin/AdminDashboard';
import AdminUserManagement from './components/admin/AdminUserManagement';
import AdminStats from './components/admin/AdminStats';

// Importa la configurazione i18n
import './i18n';

const App = () => {
  useEffect(() => {
    // Inizializza Vercel Analytics
    injectAnalytics();

    // Inizializza Vercel Speed Insights
    injectSpeedInsights();
  }, []);

  // Funzione per tracciare l'arrivo sulla pagina di login
  const trackLoginPageView = () => {
    track('Login Page View');
  };

  // Funzione per tracciare l'iscrizione avvenuta con successo
  const trackSuccessfulSignup = () => {
    track('Successful Signup');
  };

  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-background text-primary">
          <Routes>
            <Route path="/" element={
              <>
                <Header />
                <HeroSection />
                <main>
                  <Benefits />
                  <Features />
                  <FAQ />
                </main>
                <Footer />
              </>
            } />
            <Route 
              path="/login" 
              element={
                <LoginPage 
                  onPageView={trackLoginPageView} 
                  onSuccessfulSignup={trackSuccessfulSignup} 
                />
              } 
            />
            <Route path="/area-personale" element={<AreaPersonale />} />
            <Route path="/chatbot" element={<ChatBotPage />} />
            <Route path="/chatbot-config" element={<ChatbotConfig />} />
            <Route path="/voicebot-config" element={<VoicebotConfig />} />
            <Route path="/verify-magic-link" element={<VerifyMagicLink />} />
            <Route path="/verify-phone" element={<VerifyPhonePage />} />
            <Route path="/chatbot-analytics" element={<ChatbotAnalytics />} />
            <Route path="/voicebot-analytics" element={<VoiceBotAnalytics />} />
            <Route path="/log-transazioni" element={<LogTransazioni />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            {/* Route per il pannello admin */}
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route 
              path="/admin" 
              element={
                <ProtectedAdminRoute>
                  <AdminDashboard />
                </ProtectedAdminRoute>
              } 
            >
              <Route index element={<AdminStats />} />
              <Route path="users" element={<AdminUserManagement />} />
              <Route path="stats" element={<AdminStats />} />
            </Route>

            {/* Reindirizza tutte le route non corrispondenti alla home */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <CookieConsent />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;