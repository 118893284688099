import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FeatureItem = ({ title, description, videoSrc, isReversed }) => {
  const { t } = useTranslation();
  return (
    <div className={`flex flex-col ${isReversed ? 'lg:flex-row-reverse' : 'lg:flex-row'} items-center mb-24 animate-fade-in`}>
      <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
        <div className="video-container aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-xl">
          <video 
            className="video-element"
            autoPlay 
            loop 
            muted 
            playsInline
            preload="auto"
          >
            <source src={videoSrc} type="video/mp4" />
            {t('featuresSection.videoUnsupported')}
          </video>
        </div>
      </div>
      <div className={`w-full lg:w-1/2 ${isReversed ? 'lg:pr-16' : 'lg:pl-16'}`}>
        <h3 className="text-3xl font-bold mb-4 text-primary">{t(title)}</h3>
        <p className="text-primary text-lg mb-6">{t(description)}</p>
        <Link to="/login" className="cta-button text-lg px-8 py-3 rounded-full hover:animate-wiggle inline-block">
          {t('featuresSection.ctaButton')}
        </Link>
      </div>
    </div>
  );
};

const Features = () => {
  const { t } = useTranslation();
  
  const features = [
    {
      title: "featuresSection.feature1.title",
      description: "featuresSection.feature1.description",
      videoSrc: "/demo2.mp4"
    },
    {
      title: "featuresSection.feature2.title",
      description: "featuresSection.feature2.description",
      videoSrc: "/demo3.mp4"
    },
    {
      title: "featuresSection.feature3.title",
      description: "featuresSection.feature3.description",
      videoSrc: "/demo4.mp4"
    },
    {
      title: "featuresSection.feature4.title",
      description: "featuresSection.feature4.description",
      videoSrc: "/demo1.mp4"
    },
  ];

  return (
    <section id="features" className="py-24 bg-background">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl lg:text-5xl font-bold text-center text-primary mb-4 animate-fade-in-down">
          {t('featuresSection.mainTitle')}
        </h2>
        <p className="text-xl lg:text-2xl text-center text-primary mb-16 animate-fade-in-up">
          {t('featuresSection.subTitle')}
        </p>
        
        {features.map((feature, index) => (
          <FeatureItem 
            key={index}
            {...feature}
            isReversed={index % 2 !== 0}
          />
        ))}
      </div>
    </section>
  );
};

export default Features;