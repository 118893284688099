import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    // Controlla se c'è un token salvato nel localStorage
    const token = localStorage.getItem('adminToken');
    if (token) {
      // Se c'è un token, impostiamo lo stato dell'admin
      setAdmin({ token });
    }
  }, []);

  const login = (token) => {
    setAdmin({ token });
    localStorage.setItem('adminToken', token);
  };

  const logout = () => {
    setAdmin(null);
    localStorage.removeItem('adminToken');
  };

  return (
    <AuthContext.Provider value={{ admin, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);