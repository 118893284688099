import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ParticleBackground from './ParticleBackground';
import './ChatBot.css';
import EditableOrderConfirmation from './EditableOrderConfirmation';

const ChatBotPage = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orderConfirmation, setOrderConfirmation] = useState(null);
  const [orderProcessed, setOrderProcessed] = useState(false);
  const [currentStep, setCurrentStep] = useState('welcome');
  const [currentInfo, setCurrentInfo] = useState({
    orderItems: [],
    additionalInfo: {},
    currentStep: 'welcome'
  });
  const [insufficientCredit, setInsufficientCredit] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);

  const resetConversation = () => {
    window.location.reload();
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const token = localStorage.getItem('token');
        console.log('Token stored:', token);
        const response = await axios.get('${process.env.REACT_APP_API_URL}/api/chatbot/get-config', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setConfig(response.data);
        console.log("Config in ChatBotPage:", response.data);
        setLoading(false);
        await sendWelcomeMessage();
      } catch (error) {
        console.error('Errore nel recupero della configurazione:', error);
        setLoading(false);
        if (error.response && error.response.status === 403) {
          setInsufficientCredit(true);
          const errorMessage = { text: "Credito insufficiente, scrivere a info@recenstar.com", sender: 'bot' };
          setMessages([errorMessage]);
        }
      }
    };
  
    fetchConfig();
  }, []);

  const sendWelcomeMessage = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Token stored:', token);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/chat`, 
        {
          message: 'welcome',
          conversationHistory: [],
          currentInfo: { orderItems: [], additionalInfo: {}, currentStep: 'welcome' }
        },
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      
      if (response.data.insufficientCredit) {
        setInsufficientCredit(true);
        const botResponse = { text: response.data.message || "Credito insufficiente, scrivere a info@recenstar.com", sender: 'bot' };
        setMessages([botResponse]);
      } else {
        const botResponse = { text: response.data.reply, sender: 'bot' };
        setMessages([botResponse]);
        setCurrentStep(response.data.nextStep);
        setCurrentInfo(response.data.currentInfo);
      }
    } catch (error) {
      console.error('Error in sending welcome message:', error);
      if (error.response && error.response.status === 403) {
        setInsufficientCredit(true);
        const errorMessage = { text: "Credito insufficiente, scrivere a info@recenstar.com", sender: 'bot' };
        setMessages([errorMessage]);
      } else {
        const errorMessage = { text: "Si è verificato un errore nell'avvio della conversazione.", sender: 'bot' };
        setMessages([errorMessage]);
      }
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() === '' || insufficientCredit) return;
    const newMessage = { text: inputMessage, sender: 'user' };
    setMessages(prev => [...prev, newMessage]);
    setInputMessage('');
  
    try {
      const token = localStorage.getItem('token');
      console.log('Token stored:', token);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/chat`, {
        message: inputMessage,
        conversationHistory: messages.map(msg => ({
          role: msg.sender === 'user' ? 'user' : 'assistant',
          content: msg.text
        })),
        orderProcessed: orderProcessed,
        currentInfo: currentInfo
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
  
      if (response.data.insufficientCredit) {
        setInsufficientCredit(true);
        const botResponse = { text: response.data.message || "Credito insufficiente, scrivere a info@recenstar.com", sender: 'bot' };
        setMessages(prev => [...prev, botResponse]);
      } else {
        if (response.data.reply) {
          const botResponse = { text: response.data.reply, sender: 'bot' };
          setMessages(prev => [...prev, botResponse]);
        }
        setCurrentInfo(response.data.currentInfo);
        setCurrentStep(response.data.nextStep);
  
        if (response.data.orderConfirmation) {
          setOrderConfirmation(response.data.orderConfirmation);
          setIsEditing(true);
          console.log('Order confirmation received:', response.data.orderConfirmation);
        }
      }
    } catch (error) {
      console.error('Error in chat communication:', error);
      if (error.response && error.response.status === 403) {
        setInsufficientCredit(true);
        const errorMessage = { text: "Credito insufficiente, scrivere a info@recenstar.com", sender: 'bot' };
        setMessages(prev => [...prev, errorMessage]);
      } else {
        const errorMessage = { text: "Mi dispiace, c'è stato un errore nella comunicazione.", sender: 'bot' };
        setMessages(prev => [...prev, errorMessage]);
      }
    }
  };

  const handleConfirmOrder = async (updatedOrderDetails) => {
    try {
      const token = localStorage.getItem('token');
      console.log('Token stored:', token);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/confirm-order`, {
        orderDetails: updatedOrderDetails
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      // Usa i dati dalla risposta
      console.log('Risposta dal server:', response.data);
      const confirmationMessage = {
        text: config && (config.tipologiaAzienda === 'Ristorante' || config.tipologiaAzienda === 'Ecommerce')
          ? "Grazie! Il tuo ordine è stato confermato!"
          : "Grazie! La tua prenotazione è stata confermata!",
        sender: 'bot'
      };
      setMessages(prev => [...prev, confirmationMessage]);
      setOrderConfirmation(null);
      setOrderProcessed(true);
      setCurrentStep('completed');
      setIsEditing(false);
    } catch (error) {
      console.error('Error in order/booking confirmation:', error);
      let errorMessage = {
        text: "Mi dispiace, c'è stato un errore nella conferma.",
        sender: 'bot'
      };
      if (error.response && error.response.data && error.response.data.details) {
        errorMessage.text += ` ${error.response.data.details}`;
      }
      setMessages(prev => [...prev, errorMessage]);
    }
  };

  const handleAnnulla = () => {
    setOrderConfirmation(null);
    setIsEditing(false);
    // Aggiungi un messaggio di annullamento alla conversazione
    setMessages(prev => [...prev, { text: "Ordine annullato. Come posso aiutarti ancora?", sender: 'bot' }]);
  };

  return (
    <div className="relative min-h-screen bg-gray-900 flex flex-col items-center justify-center p-4">
      <ParticleBackground />
      <div className="relative z-10 w-full max-w-4xl text-center mb-8">
        <h1 className="text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-500 to-pink-500">
          {config?.nomeAzienda || "ChatBot"}
        </h1>
        <p className="text-xl text-white mb-4">
          Benvenuti nel nostro assistente virtuale!
        </p>
      </div>

      <div className="relative z-10 w-full max-w-lg bg-white shadow-lg rounded-lg overflow-hidden flex flex-col">
        <div className="bg-green-500 text-white p-4 flex items-center">
          <div className="w-10 h-10 rounded-full bg-white mr-3 overflow-hidden flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6 text-green-500">
              <path d="M12 2L2 12h3v9h14v-9h3L12 2z"/>
              <path d="M12 15l-2-2 2-2 2 2-2 2z"/>
            </svg>
          </div>
          <h1 className="text-xl font-semibold">{config?.nomeAzienda || "ChatBot"}</h1>
        </div>

        <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4 space-y-4 chat-background" style={{height: "calc(100vh - 300px)"}}>
          {messages.map((message, index) => (
            <div key={index} className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
              <div className={`max-w-xs lg:max-w-md px-4 py-2 rounded-lg ${
                message.sender === 'user' 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-white text-gray-800'
              } shadow break-words`}>
                {message.text}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        {insufficientCredit && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Attenzione!</strong>
            <span className="block sm:inline"> Credito insufficiente. Contatta info@recenstar.com per ricaricare.</span>
          </div>
        )}

        <div className="relative">
          <form onSubmit={handleSendMessage} className="bg-gray-200 p-4 flex items-center">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              className="flex-1 bg-white rounded-full px-4 py-2 focus:outline-none text-black"
              placeholder={insufficientCredit ? "Credito insufficiente" : "Scrivi un messaggio..."}
              disabled={currentStep === 'completed' || insufficientCredit}
            />
            <button type="submit" className="ml-2 bg-green-500 text-white rounded-full p-2 focus:outline-none hover:bg-green-600" disabled={currentStep === 'completed'}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
              </svg>
            </button>
          </form>
        </div>
      </div>

      {orderConfirmation && !isEditing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
            <h3 className="text-xl font-bold mb-4 text-gray-800">
              {config.requiresDelivery ? "Conferma il tuo ordine:" : "Conferma la tua prenotazione:"}
            </h3>
            <p className="text-gray-600 mb-4 whitespace-pre-line">
              {orderConfirmation.summary}
            </p>
            <div className="flex justify-end space-x-4">
              <button 
                onClick={() => setIsEditing(true)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
              >
                Modifica
              </button>
              <button 
                onClick={() => handleConfirmOrder(orderConfirmation)}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
              >
                {config.requiresDelivery ? "Conferma Ordine" : "Conferma Prenotazione"}
              </button>
            </div>
          </div>
        </div>
      )}
      {isEditing && orderConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <EditableOrderConfirmation
              initialOrderDetails={orderConfirmation}
              onConfirm={handleConfirmOrder}
              onCancel={() => setIsEditing(false)}
              onAnnulla={handleAnnulla}
              config={{
                tipologiaAzienda: orderConfirmation?.tipologiaAzienda,
                requiresDelivery: orderConfirmation?.requiresDelivery
              }}
            />
          </div>
        </div>
      )} 

      <div className="relative z-10 mt-8 space-x-4">
        <button 
          onClick={resetConversation} 
          className="bg-red-500 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-red-600 transition duration-300 inline-block"
        >
          Resetta Conversazione
        </button>
        <Link to="/area-personale" className="bg-indigo-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-indigo-700 transition duration-300 inline-block">
          Ritorna all'Area Personale
        </Link>
      </div>
    </div>
  );
};

export default ChatBotPage;