import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ParticleBackground from './ParticleBackground';
import DashboardAnalytics from './DashboardAnalytics';

const ChatBotAnalytics = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState('24hours');
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  const [resetStatus, setResetStatus] = useState(null);

  useEffect(() => {
    fetchAnalytics();
  }, [dateRange]);

  const fetchAnalytics = async () => {
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/analytics?range=${dateRange}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setAnalyticsData(response.data);
    } catch (err) {
      console.error('Errore nel recupero dei dati analitici:', err);
      setError(err.response?.data?.error || err.message || 'Errore sconosciuto');
    } finally {
      setLoading(false);
    }
  };

  const handleResetStats = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete('${process.env.REACT_APP_API_URL}/api/analytics/reset', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setResetStatus('success');
      fetchAnalytics();
    } catch (error) {
      console.error('Errore durante il reset delle statistiche:', error);
      setResetStatus('error');
    } finally {
      setShowResetConfirmation(false);
      setTimeout(() => setResetStatus(null), 3000);
    }
  };

  return (
    <div className="relative min-h-screen bg-gray-900 flex flex-col items-center justify-center p-4">
      <ParticleBackground />
      <div className="relative z-10 w-full max-w-6xl text-white">
        <h1 className="text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-500 to-pink-500">
          Dashboard Analytics
        </h1>
        
        <div className="mb-4 flex justify-between items-center">
          <div>
            <label htmlFor="dateRange" className="mr-2">Intervallo di date:</label>
            <select
              id="dateRange"
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
              className="bg-gray-700 text-white rounded p-2"
            >
              <option value="24hours">Ultime 24 ore</option>
              <option value="7days">Ultimi 7 giorni</option>
              <option value="30days">Ultimi 30 giorni</option>
              <option value="allTime">Tutto il tempo</option>
            </select>
          </div>
          <button 
            onClick={() => setShowResetConfirmation(true)}
            className="bg-red-600 text-white px-4 py-2 rounded-lg text-sm font-semibold hover:bg-red-700 transition duration-300"
          >
            Resetta Statistiche
          </button>
        </div>

        {loading ? (
          <p>Caricamento dati in corso...</p>
        ) : error ? (
          <div className="bg-red-600 text-white p-4 rounded-lg mb-4">
            <p className="font-bold">Errore nel recupero dei dati:</p>
            <p>{error}</p>
            <button 
              onClick={fetchAnalytics} 
              className="mt-2 bg-white text-red-600 px-4 py-2 rounded hover:bg-red-100 transition-colors"
            >
              Riprova
            </button>
          </div>
        ) : (
          <DashboardAnalytics data={analyticsData} />
        )}

        <div className="mt-8">
          <Link to="/area-personale" className="bg-indigo-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-indigo-700 transition duration-300 inline-block">
            Torna all'Area Personale
          </Link>
        </div>

        {showResetConfirmation && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg text-black">
              <h2 className="text-2xl font-bold mb-4">Conferma Reset Statistiche</h2>
              <p className="mb-4">Sei sicuro di voler eliminare tutte le statistiche? Questa azione non può essere annullata.</p>
              <div className="flex justify-end space-x-4">
                <button 
                  onClick={() => setShowResetConfirmation(false)}
                  className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400 transition-colors"
                >
                  Annulla
                </button>
                <button 
                  onClick={handleResetStats}
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition-colors"
                >
                  Conferma Reset
                </button>
              </div>
            </div>
          </div>
        )}

        {resetStatus && (
          <div className={`fixed top-4 right-4 p-4 rounded-md shadow-lg ${
            resetStatus === 'success' ? 'bg-green-500' : 'bg-red-500'
          } text-white`}>
            {resetStatus === 'success' 
              ? 'Statistiche resettate con successo!' 
              : 'Errore durante il reset delle statistiche.'}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatBotAnalytics;