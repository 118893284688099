import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const API_URL = 'https://recenstar-server.onrender.com'; // Sostituisci con l'URL corretto del tuo backend

const LogTransazioni = () => {
  const [transactions, setTransactions] = useState([]);
  const [userStats, setUserStats] = useState({
    totalChatbotMessages: 0,
    totalSimulatedCalls: 0
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(15);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTransactions();
    fetchUserStats();
  }, []);

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/api/transactions`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setTransactions(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Errore nel recupero delle transazioni:', error);
      setError('Impossibile caricare le transazioni. Riprova più tardi.');
    } finally {
      setLoading(false);
    }
  };

  const fetchUserStats = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/api/user-stats`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setUserStats(response.data);
    } catch (error) {
      console.error('Errore nel recupero delle statistiche utente:', error);
      // Non impostiamo un errore qui perché non è critico per la visualizzazione della pagina
    }
  };

  // Ottieni le transazioni correnti
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  // Cambia pagina
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) return <div className="p-4 bg-gray-900 text-white">Caricamento...</div>;
  if (error) return <div className="p-4 bg-gray-900 text-white">Errore: {error}</div>;

  return (
    <div className="p-4 bg-gray-900 text-white">
      <h1 className="text-2xl font-bold mb-4">Log Transazioni</h1>
      
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Statistiche Utente</h2>
        <p>Totale messaggi con chatbot: {userStats.totalChatbotMessages}</p>
        <p>Totale chiamate simulate: {userStats.totalSimulatedCalls}</p>
      </div>

      {transactions.length > 0 ? (
        <>
          <table className="w-full mb-6">
            <thead>
              <tr className="bg-gray-800">
                <th className="p-2 text-left">Data</th>
                <th className="p-2 text-left">Tipo</th>
                <th className="p-2 text-left">Dettagli</th>
                <th className="p-2 text-left">Importo</th>
              </tr>
            </thead>
            <tbody>
              {currentTransactions.map((transaction, index) => (
                <tr key={index} className="border-b border-gray-700">
                  <td className="p-2">{new Date(transaction.timestamp).toLocaleString()}</td>
                  <td className="p-2">{transaction.type}</td>
                  <td className="p-2">{transaction.details}</td>
                  <td className="p-2">{transaction.amount.toFixed(2)}€</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Paginazione */}
          <div className="flex justify-center mt-4">
            {Array.from({ length: Math.ceil(transactions.length / transactionsPerPage) }, (_, i) => (
              <button
                key={i}
                onClick={() => paginate(i + 1)}
                className={`mx-1 px-3 py-1 rounded ${
                  currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-700 text-gray-300'
                }`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </>
      ) : (
        <p>Nessuna transazione disponibile.</p>
      )}

      <Link to="/area-personale" className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
        Torna all'Area Personale
      </Link>
    </div>
  );
};

export default LogTransazioni;