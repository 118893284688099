import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'lucide-react';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    setIsVisible(!consent);
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-[#1E1E1E] text-white p-4 shadow-lg animate-fade-in-up" style={{zIndex: 9999}}>
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <p className="text-sm mr-4">
          {t('cookieConsent.message')}
        </p>
        <div className="flex items-center">
          <button
            onClick={handleReject}
            className="text-[#FAD403] hover:underline mr-4 text-sm"
          >
            {t('cookieConsent.reject')}
          </button>
          <button
            onClick={handleAccept}
            className="bg-[#FAD403] text-[#0B0B0B] px-4 py-2 rounded hover:bg-opacity-90 transition duration-300 text-sm"
          >
            {t('cookieConsent.accept')}
          </button>
          <button
            onClick={() => setIsVisible(false)}
            className="ml-4 text-gray-400 hover:text-white"
            aria-label={t('cookieConsent.close')}
          >
            <X size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;