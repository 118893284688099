import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const VerifyMagicLink = () => {
  const [verificationStatus, setVerificationStatus] = useState('verifying');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      // Controlla se l'utente è già autenticato
      const existingToken = localStorage.getItem('token');
      if (existingToken) {
        navigate('/area-personale');
        return;
      }

      const searchParams = new URLSearchParams(location.search);
      const magicLinkToken = searchParams.get('token');

      if (!magicLinkToken) {
        setVerificationStatus('error');
        setError('Token mancante');
        return;
      }

      try {
        console.log('Inizio verifica token:', magicLinkToken);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/verify-magic-link`, {
          params: { token: magicLinkToken },
          withCredentials: true
        });
        console.log('Risposta dal server:', response.data);
        
        if (response.data && response.data.token) {
          localStorage.setItem('token', response.data.token);
          console.log('Token salvato:', localStorage.getItem('token'));
          localStorage.setItem('user', JSON.stringify(response.data.user));
          setVerificationStatus('success');
          navigate('/area-personale', { replace: true });
        } else {
          throw new Error('Token non ricevuto dal server');
        }
      } catch (error) {
        console.error('Errore durante la verifica del Magic Link:', error);
        setVerificationStatus('error');
        setError(error.response?.data?.error || error.message || 'Si è verificato un errore durante la verifica del Magic Link');
      }
    };

    verifyToken();
  }, [location, navigate]);

  if (verificationStatus === 'verifying') {
    return (
      <div>
        <p>Verifica del Magic Link in corso...</p>
        <div>Caricamento...</div> {/* Qui potresti aggiungere uno spinner */}
      </div>
    );
  }
  
  if (verificationStatus === 'error') {
    return (
      <div>
        <p>Si è verificato un errore durante la verifica del Magic Link:</p>
        <p>{error}</p>
        <button onClick={() => navigate('/login', { replace: true })}>Torna al login</button>
        <button onClick={() => window.location.reload()}>Riprova</button>
      </div>
    );
  }

  if (verificationStatus === 'success') {
    return <div>Verifica completata con successo. Reindirizzamento in corso...</div>;
  }

  return null;
};

export default VerifyMagicLink;