import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ParticleBackground from './ParticleBackground';

const businessTypes = [
  'Ristorante', 'Palestra', 'Dentista', 'Centro estetico', 'Hotel', 'Ecommerce'
];

const businessCharacteristics = {
  'Ristorante': `Sei un assistente virtuale AI che lavora al ristorante NomeAzienda. Il tuo compito è aiutare i clienti in modo divertente e coinvolgente con le loro ordinazioni e prenotazioni presso NomeAzienda. Simula di essere un dipendente del ristorante, confermando le ordinazioni, gli orari e rispondendo alle domande sui menu. 
Quando qualcuno completa un ordine per consegna a domicilio, chiedi cortesemente il loro indirizzo personale e numero di telefono. Se desiderano prenotare un tavolo, chiedi quante persone saranno presenti, se preferiscono mangiare all'interno o all'esterno, il nome per la prenotazione e il numero di telefono. Sii sempre educato, gentile e usa emoji per rendere l'interazione più piacevole. Puoi fornire dettagli sui piatti del menu e assistenza riguardo a qualsiasi altra richiesta.
Ricorda: devi essere divertente, ma anche professionale e rispettoso.`,
  'Palestra': `Sei un assistente virtuale AI che lavora presso la palestra NomeAzienda. Il tuo compito è aiutare i clienti a iscriversi, prenotare lezioni e fornire informazioni sui vari programmi di allenamento. Simula di essere un dipendente della palestra, confermando le iscrizioni e rispondendo alle domande sui servizi offerti.
Quando qualcuno desidera iscriversi o prenotare una lezione, chiedi il loro nome, il tipo di lezione a cui sono interessati, una preferenza per la data e l'ora, e il numero di telefono per confermare la prenotazione. Sii sempre energico, motivante e gentile, utilizzando un tono che trasmetta entusiasmo e positività.
Ricorda: devi essere motivante, energico e rispettoso, aiutando i clienti a raggiungere i loro obiettivi di fitness.`,
  'Dentista': `Sei un assistente virtuale AI che lavora presso lo studio dentistico NomeAzienda. Il tuo compito è aiutare i pazienti a prenotare appuntamenti, rispondere alle loro domande e fornire informazioni sui servizi offerti. Simula di essere un dipendente dello studio dentistico, confermando gli appuntamenti e fornendo dettagli sui trattamenti disponibili.
Quando qualcuno desidera prenotare una visita, chiedi il loro nome, il motivo della visita, una preferenza per la data e l'ora, e il numero di telefono per confermare l'appuntamento. Sii sempre professionale, rassicurante e gentile, utilizzando un tono che trasmetta fiducia e competenza.
Ricorda: devi essere accogliente, professionale e rispettoso, assicurandoti che i pazienti si sentano a loro agio e ben informati.`,
  'Centro estetico': `Sei un assistente virtuale AI che lavora presso il centro estetico NomeAzienda. Il tuo compito è aiutare i clienti a prenotare trattamenti, rispondere alle loro domande e fornire informazioni sui servizi di bellezza offerti. Simula di essere un dipendente del centro estetico, confermando le prenotazioni e fornendo dettagli sui trattamenti disponibili.
Quando qualcuno desidera prenotare un trattamento, chiedi il loro nome, il tipo di trattamento richiesto, una preferenza per la data e l'ora, e il numero di telefono per confermare la prenotazione. Sii sempre cortese, rilassante e gentile, utilizzando un tono che trasmetta calma e professionalità.
Ricorda: devi essere accogliente, rilassante e rispettoso, garantendo un'esperienza di benessere ai clienti.`,
  'Hotel': `Sei un assistente virtuale AI che lavora presso l'hotel NomeAzienda. Il tuo compito è aiutare i clienti a prenotare camere, rispondere alle loro domande e fornire informazioni sui servizi dell'hotel. Simula di essere un dipendente dell'hotel, confermando le prenotazioni e fornendo dettagli sui servizi disponibili.
Quando qualcuno desidera prenotare una camera, chiedi il loro nome, il tipo di camera richiesto, una preferenza per la data di arrivo e partenza, e il numero di telefono per confermare la prenotazione. Sii sempre professionale, accogliente e gentile, utilizzando un tono che trasmetta ospitalità e cortesia.
Ricorda: devi essere accogliente, professionale e rispettoso, garantendo un'esperienza piacevole e senza intoppi ai clienti.`,
  'Ecommerce': `Sei un assistente virtuale AI che lavora presso il Ecommerce NomeAzienda. Il tuo compito è aiutare i clienti a trovare i capi che desiderano, rispondere alle loro domande e fornire informazioni sulle collezioni disponibili. Simula di essere un dipendente del negozio, confermando la disponibilità degli articoli e fornendo dettagli sui prodotti.
Quando qualcuno desidera acquistare un capo, chiedi il loro nome, il tipo di capo richiesto, la taglia e il colore preferiti, e il numero di telefono per confermare l'ordine. Sii sempre alla moda, gentile e professionale, utilizzando un tono che trasmetta stile e attenzione ai dettagli.
Ricorda: devi essere alla moda, professionale e rispettoso, aiutando i clienti a trovare l'outfit perfetto.`
};

const ChatbotConfig = () => {
  const [config, setConfig] = useState({
    tipologiaAzienda: '',
    nomeAzienda: '',
    infoAzienda: '',
    caratteristicheAgente: '',
    lingua: 'italiano',
    menu: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [notification, setNotification] = useState({ show: false, message: '', type: 'success' });
  const [isNewUser, setIsNewUser] = useState(true);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/chatbot/get-config`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setConfig(prevConfig => ({
          ...prevConfig,
          ...response.data,
          menu: response.data.menu || []
        }));
        setIsNewUser(false);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setIsNewUser(true);
        } else {
          setError('Si è verificato un errore nel recupero della configurazione. Riprova più tardi.');
          setIsNewUser(false);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchConfig();
  }, []);

  useEffect(() => {
    if (config.tipologiaAzienda) {
      setConfig(prevConfig => ({
        ...prevConfig,
        caratteristicheAgente: businessCharacteristics[config.tipologiaAzienda]
      }));
    }
  }, [config.tipologiaAzienda]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfig(prevConfig => ({
      ...prevConfig,
      [name]: value
    }));
  };

  const handleAddMenuItem = () => {
    setConfig(prevConfig => ({
      ...prevConfig,
      menu: [...prevConfig.menu, { nome: '', prezzo: '' }]
    }));
  };

  const handleRemoveMenuItem = (index) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      menu: prevConfig.menu.filter((_, i) => i !== index)
    }));
  };

  const handleMenuItemChange = (index, field, value) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      menu: prevConfig.menu.map((item, i) => 
        i === index ? { ...item, [field]: value } : item
      )
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
  
    try {
      const token = localStorage.getItem('token');
      const dataToSend = {
        ...config,
        menu: JSON.stringify(config.menu)
      };
  
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/chatbot/save-config`, dataToSend, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      setNotification({ show: true, message: 'Configurazione salvata con successo!', type: 'success' });
      setConfig(prevConfig => ({
        ...prevConfig,
        ...response.data.config
      }));
  
    } catch (err) {
      setNotification({ show: true, message: `Errore nel salvataggio della configurazione: ${err.response?.data?.error || err.message}`, type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center mt-8">Caricamento...</div>;
  }

  return (
    <div className="relative min-h-screen bg-gray-900 text-white p-8">
      <ParticleBackground />
      <div className="relative z-10 max-w-4xl mx-auto">
        <h1 className="text-5xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-500 to-pink-500">
          Configurazione Chatbot
        </h1>
        <div className="bg-gray-800 p-8 rounded-lg shadow-xl">
          {error && <div className="bg-red-600 text-white p-3 rounded mb-4">{error}</div>}
          {isNewUser && !error && (
            <div className="bg-blue-600 text-white p-3 rounded mb-4">
              Benvenuto! Sembra che sia la tua prima volta qui. Configura il tuo chatbot per iniziare.
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="tipologiaAzienda" className="block mb-2">Tipologia Azienda</label>
              <select
                id="tipologiaAzienda"
                name="tipologiaAzienda"
                value={config.tipologiaAzienda}
                onChange={handleChange}
                className="w-full p-2 bg-gray-700 rounded text-white"
                required
              >
                <option value="">Seleziona una tipologia</option>
                {businessTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="nomeAzienda" className="block mb-2">Nome Azienda</label>
              <input
                type="text"
                id="nomeAzienda"
                name="nomeAzienda"
                value={config.nomeAzienda}
                onChange={handleChange}
                className="w-full p-2 bg-gray-700 rounded text-white"
                required
              />
            </div>
            <div>
              <label htmlFor="infoAzienda" className="block mb-2">Informazioni Azienda (max 300 caratteri)</label>
              <textarea
                id="infoAzienda"
                name="infoAzienda"
                value={config.infoAzienda}
                onChange={handleChange}
                className="w-full p-2 bg-gray-700 rounded text-white"
                rows="4"
                maxLength="300"
                required
              />
            </div>
            <div>
              <label htmlFor="lingua" className="block mb-2">Lingua</label>
              <select
                id="lingua"
                name="lingua"
                value={config.lingua}
                onChange={handleChange}
                className="w-full p-2 bg-gray-700 rounded text-white"
                required
              >
                <option value="italiano">Italiano</option>
                <option value="inglese">Inglese</option>
              </select>
            </div>
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-4">Menu / Servizi / Prodotti</h2>
              {config.menu.map((item, index) => (
                <div key={index} className="mb-4 p-4 bg-gray-700 rounded">
                  <input
                    type="text"
                    placeholder="Nome"
                    value={item.nome}
                    onChange={(e) => handleMenuItemChange(index, 'nome', e.target.value)}
                    className="w-full p-2 mb-2 bg-gray-600 rounded text-white"
                  />
                  <input
                    type="number"
                    placeholder="Prezzo"
                    value={item.prezzo}
                    onChange={(e) => handleMenuItemChange(index, 'prezzo', e.target.value)}
                    className="w-full p-2 mb-2 bg-gray-600 rounded text-white"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveMenuItem(index)}
                    className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
                  >
                    Rimuovi
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddMenuItem}
                className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300"
              >
                Aggiungi elemento
              </button>
            </div>
            <button type="submit" className="w-full bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition duration-300">
              Salva Configurazione
            </button>
          </form>
        </div>
        <div className="mt-8 text-center">
          <Link to="/area-personale" className="text-indigo-400 hover:underline">
            Torna all'Area Personale
          </Link>
        </div>
        {notification.show && (
          <div className={`fixed top-4 right-4 p-4 rounded-md shadow-lg ${
            notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
          } text-white`}>
            {notification.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatbotConfig;