import React from 'react';
import { Link } from 'react-router-dom';

const AnalyticsMenu = ({ showAnalyticsMenu }) => {
  if (!showAnalyticsMenu) return null;

  return (
    <div className="mt-4 space-y-4">
      <Link to="/chatbot-analytics" className="bg-[#FAD403] text-[#0B0B0B] px-4 py-2 rounded hover:bg-opacity-90 transition duration-300 inline-block mr-4">
        Chatbot Analytics
      </Link>
      <Link to="/voicebot-analytics" className="bg-[#FAD403] text-[#0B0B0B] px-4 py-2 rounded hover:bg-opacity-90 transition duration-300 inline-block mr-4">
        Voicebot Analytics
      </Link>
      <Link to="/log-transazioni" className="bg-[#FAD403] text-[#0B0B0B] px-4 py-2 rounded hover:bg-opacity-90 transition duration-300 inline-block">
        Log Transazioni
      </Link>
    </div>
  );
};

export default AnalyticsMenu;