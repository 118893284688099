import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Footer from './Footer';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const privacyPolicySections = [
    'introduction',
    'dataCollection',
    'dataTypes',
    'consent',
    'userRights',
    'optOut',
    'dataProtection',
    'dataBreachNotification',
    'dpo',
    'minorsData',
    'internationalTransfers',
    'dataRetention',
    'cookies',
    'policyChanges',
    'contact',
    'finalConsent'
  ];

  return (
    <div className="bg-[#0B0B0B] min-h-screen text-white">
      <Header />
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center">{t('privacyPolicy.title')}</h1>

        {privacyPolicySections.map((section, index) => (
          <section key={index} className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">{t(`privacyPolicy.${section}.title`)}</h2>
            <div dangerouslySetInnerHTML={{ __html: t(`privacyPolicy.${section}.content`) }} />
          </section>
        ))}

        <p className="mt-8 text-center text-lg">
          {t('privacyPolicy.cta')}
        </p>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;