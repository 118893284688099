import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ITEMS_PER_PAGE = 10;

const AdminUserManagement = () => {
  const { admin, logout } = useAuth();
  const navigate = useNavigate();
  const [state, setState] = useState({
    users: [],
    filteredUsers: [],
    loading: true,
    error: null,
    success: null,
    editingUser: null,
    editForm: { nome: '', cognome: '', email: '', verifiedPhoneNumber: '' },
    addingCredits: null,
    creditsToAdd: 0,
    searchTerm: '',
    filterOption: 'all',
    currentPage: 1
  });

  const fetchUsers = useCallback(async () => {
    if (!admin?.token) {
      navigate('/admin/login');
      return;
    }

    try {
      const response = await fetch('${process.env.REACT_APP_API_URL}/api/admin/users', {
        headers: {
          'Authorization': `Bearer ${admin.token}`
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          logout();
          navigate('/admin/login');
          return;
        }
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      setState(prevState => ({ 
        ...prevState, 
        users: data, 
        filteredUsers: data,
        loading: false 
      }));
    } catch (err) {
      setState(prevState => ({ ...prevState, error: err.message, loading: false }));
    }
  }, [admin, navigate, logout]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setState(prevState => ({ 
      ...prevState, 
      searchTerm,
      currentPage: 1,
      filteredUsers: prevState.users.filter(user => 
        user.nome.toLowerCase().includes(searchTerm) ||
        user.cognome.toLowerCase().includes(searchTerm) ||
        user.email.toLowerCase().includes(searchTerm) ||
        (user.verifiedPhoneNumber && user.verifiedPhoneNumber.includes(searchTerm))
      )
    }));
  };

  const handleFilter = (e) => {
    const filterOption = e.target.value;
    setState(prevState => ({ 
      ...prevState, 
      filterOption,
      currentPage: 1,
      filteredUsers: prevState.users.filter(user => {
        if (filterOption === 'all') return true;
        if (filterOption === 'withPhone') return user.verifiedPhoneNumber;
        if (filterOption === 'withoutPhone') return !user.verifiedPhoneNumber;
        return true;
      })
    }));
  };

  const handlePageChange = (pageNumber) => {
    setState(prevState => ({ ...prevState, currentPage: pageNumber }));
  };

  const getCurrentPageUsers = () => {
    const startIndex = (state.currentPage - 1) * ITEMS_PER_PAGE;
    return state.filteredUsers.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  const handleEdit = (user) => {
    setState(prevState => ({
      ...prevState,
      editingUser: user,
      editForm: { 
        nome: user.nome, 
        cognome: user.cognome, 
        email: user.email, 
        verifiedPhoneNumber: user.verifiedPhoneNumber || '' 
      }
    }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!admin?.token) {
      setState(prevState => ({ ...prevState, error: 'Sessione scaduta. Effettua nuovamente il login.' }));
      logout();
      navigate('/admin/login');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/${state.editingUser._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${admin.token}`
        },
        body: JSON.stringify(state.editForm)
      });
      if (!response.ok) {
        if (response.status === 401) {
          logout();
          navigate('/admin/login');
          return;
        }
        throw new Error('Failed to update user');
      }
      setState(prevState => ({ 
        ...prevState, 
        editingUser: null, 
        success: 'User updated successfully' 
      }));
      fetchUsers();
    } catch (err) {
      setState(prevState => ({ ...prevState, error: err.message }));
    }
  };

    // Aggiungi questo stato per gestire le notifiche
    const [notification, setNotification] = useState({ show: false, message: '', type: 'success' });

  const handleAddCredits = (user) => {
    setState(prevState => ({ 
      ...prevState, 
      addingCredits: user, 
      creditsToAdd: 0 
    }));
  };

  const handleAddCreditsSubmit = async (e) => {
    e.preventDefault();
    if (!admin?.token) {
      setState(prevState => ({ ...prevState, error: 'Sessione scaduta. Effettua nuovamente il login.' }));
      logout();
      navigate('/admin/login');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/${state.addingCredits._id}/add-credits`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${admin.token}`
        },
        body: JSON.stringify({ credits: state.creditsToAdd })
      });
      if (!response.ok) {
        if (response.status === 401) {
          logout();
          navigate('/admin/login');
          return;
        }
        throw new Error('Failed to update credits');
      }
      const data = await response.json();
      setState(prevState => ({ 
        ...prevState, 
        addingCredits: null, 
        success: `Credits ${state.creditsToAdd >= 0 ? 'added' : 'removed'} successfully` 
      }));
      fetchUsers();
    } catch (err) {
      setState(prevState => ({ ...prevState, error: err.message }));
    }
  };

  const handleCreditChange = async (userId, amount) => {
    try {
      const response = await axios.post('${process.env.REACT_APP_API_URL}/api/admin/updateCredit', 
        { userId, amount }, 
        {
          headers: { 'Authorization': `Bearer ${admin.token}` }
        }
      );
      
      if (response.data.success) {
        // Aggiorna lo stato locale
        setState(prevState => ({
          ...prevState,
          users: prevState.users.map(user => 
            user._id === userId ? { ...user, credit: response.data.newCredit } : user
          ),
          filteredUsers: prevState.filteredUsers.map(user => 
            user._id === userId ? { ...user, credit: response.data.newCredit } : user
          )
        }));
        setNotification({ show: true, message: `Credito aggiornato con successo`, type: 'success' });
      } else {
        throw new Error(response.data.error || 'Errore nell\'aggiornamento del credito');
      }
    } catch (error) {
      console.error('Errore nell\'aggiornamento del credito:', error);
      setNotification({ show: true, message: 'Errore nell\'aggiornamento del credito', type: 'error' });
    }
  };

  const handleDeleteUser = async (userId) => {
    if (!admin?.token) {
      setState(prevState => ({ ...prevState, error: 'Sessione scaduta. Effettua nuovamente il login.' }));
      logout();
      navigate('/admin/login');
      return;
    }
    if (window.confirm('Sei sicuro di voler cancellare definitivamente questo utente? Questa azione non può essere annullata.')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/users/${userId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${admin.token}`
          }
        });
        if (!response.ok) {
          if (response.status === 401) {
            logout();
            navigate('/admin/login');
            return;
          }
          throw new Error('Errore nella cancellazione dell\'utente');
        }
        setState(prevState => ({
          ...prevState,
          users: prevState.users.filter(user => user._id !== userId),
          filteredUsers: prevState.filteredUsers.filter(user => user._id !== userId),
          success: 'Utente cancellato definitivamente dal database'
        }));
      } catch (error) {
        setState(prevState => ({ ...prevState, error: error.message }));
      }
    }
  };
  

  useEffect(() => {
    if (state.error || state.success) {
      const timer = setTimeout(() => {
        setState(prevState => ({ ...prevState, error: null, success: null }));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [state.error, state.success]);

  if (state.loading) return <div className="text-admin-text">Loading...</div>;

  return (
    <div className="container mx-auto px-4 py-8 bg-admin-bg text-admin-text">
      {/* Componente di notifica */}
      {notification.show && (
        <div className={`fixed top-4 right-4 p-4 rounded-md shadow-lg ${
          notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
        } text-white`}>
          {notification.message}
        </div>
      )}
      <h2 className="text-2xl font-bold mb-4 text-admin-accent">User Management</h2>
      {state.error && <div className="bg-red-500 text-white p-2 mb-4 rounded">{state.error}</div>}
      {state.success && <div className="bg-green-500 text-white p-2 mb-4 rounded">{state.success}</div>}
      
      <div className="mb-4 flex items-center">
        <input
          type="text"
          placeholder="Search users..."
          value={state.searchTerm}
          onChange={handleSearch}
          className="p-2 mr-2 bg-admin-secondary text-admin-text rounded"
        />
        <select
          value={state.filterOption}
          onChange={handleFilter}
          className="p-2 bg-admin-secondary text-admin-text rounded"
        >
          <option value="all">All Users</option>
          <option value="withPhone">Users with Phone</option>
          <option value="withoutPhone">Users without Phone</option>
        </select>
      </div>
      
      <table className="min-w-full bg-admin-secondary">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-admin-hover text-left">Name</th>
            <th className="py-2 px-4 border-b border-admin-hover text-left">Email</th>
            <th className="py-2 px-4 border-b border-admin-hover text-left">Phone</th>
            <th className="py-2 px-4 border-b border-admin-hover text-left">Available Credits</th>
            <th className="py-2 px-4 border-b border-admin-hover text-left">Voicebot Calls</th>
            <th className="py-2 px-4 border-b border-admin-hover text-left">Chatbot Messages</th>
            <th className="py-2 px-4 border-b border-admin-hover text-left">Time on Platform</th>
            <th className="py-2 px-4 border-b border-admin-hover text-left">Credito Residuo</th>
            <th className="py-2 px-4 border-b border-admin-hover text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {getCurrentPageUsers().map(user => (
            <tr key={user._id} className="hover:bg-admin-hover">
              <td className="py-2 px-4 border-b border-admin-hover">{user.nome} {user.cognome}</td>
              <td className="py-2 px-4 border-b border-admin-hover">{user.email}</td>
              <td className="py-2 px-4 border-b border-admin-hover">{user.verifiedPhoneNumber || 'N/A'}</td>
              <td className="py-2 px-4 border-b border-admin-hover">{3 - (user.phoneNumberDeletions || 0)}</td>
              <td className="py-2 px-4 border-b border-admin-hover">{user.voicebotCalls || 0}</td>
              <td className="py-2 px-4 border-b border-admin-hover">{user.chatbotMessages || 0}</td>
              <td className="py-2 px-4 border-b border-admin-hover">{formatTime(user.timeSpentOnPlatform || 0)}</td>
              <td className="py-2 px-4 border-b border-admin-hover">{user.credit.toFixed(2)}€</td>
              <td className="py-2 px-4 border-b border-admin-hover">
              <button 
                onClick={() => handleCreditChange(user._id, 1)}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded mr-1 transition duration-300"
              >
                +1€
              </button>
              <button 
                onClick={() => handleCreditChange(user._id, -1)}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded mr-1 transition duration-300"
              >
                -1€
              </button>
                <button 
                  onClick={() => handleEdit(user)}
                  className="bg-admin-accent hover:bg-admin-secondary text-admin-bg font-bold py-1 px-2 rounded mr-2 transition duration-300"
                >
                  Edit
                </button>
                <button 
                  onClick={() => handleAddCredits(user)}
                  className="bg-admin-secondary hover:bg-admin-accent text-admin-text font-bold py-1 px-2 rounded mr-2 transition duration-300"
                >
                  Edit Credits
                </button>
                <button 
                  onClick={() => handleDeleteUser(user._id)}
                  className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2 transition duration-300"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Paginazione */}
      <div className="mt-4 flex justify-center">
        {Array.from({ length: Math.ceil(state.filteredUsers.length / ITEMS_PER_PAGE) }, (_, i) => i + 1).map(number => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`mx-1 px-3 py-1 rounded ${
              number === state.currentPage
                ? 'bg-admin-accent text-admin-bg'
                : 'bg-admin-secondary text-admin-text'
            }`}
          >
            {number}
          </button>
        ))}
      </div>

      {state.editingUser && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-admin-secondary p-6 rounded-lg">
            <h3 className="text-xl font-bold mb-4 text-admin-accent">Edit User</h3>
            <form onSubmit={handleEditSubmit}>
              <input
                type="text"
                value={state.editForm.nome}
                onChange={(e) => setState(prevState => ({ ...prevState, editForm: { ...prevState.editForm, nome: e.target.value } }))}
                placeholder="First Name"
                className="w-full p-2 mb-2 bg-admin-bg text-admin-text rounded"
              />
              <input
                type="text"
                value={state.editForm.cognome}
                onChange={(e) => setState(prevState => ({ ...prevState, editForm: { ...prevState.editForm, cognome: e.target.value } }))}
                placeholder="Last Name"
                className="w-full p-2 mb-2 bg-admin-bg text-admin-text rounded"
              />
              <input
                type="email"
                value={state.editForm.email}
                onChange={(e) => setState(prevState => ({ ...prevState, editForm: { ...prevState.editForm, email: e.target.value } }))}
                placeholder="Email"
                className="w-full p-2 mb-2 bg-admin-bg text-admin-text rounded"
              />
              <input
                type="tel"
                value={state.editForm.verifiedPhoneNumber}
                onChange={(e) => setState(prevState => ({ ...prevState, editForm: { ...prevState.editForm, verifiedPhoneNumber: e.target.value } }))}
                placeholder="Phone Number"
                className="w-full p-2 mb-4 bg-admin-bg text-admin-text rounded"
              />
              <div className="flex justify-end">
                <button type="button" onClick={() => setState(prevState => ({ ...prevState, editingUser: null }))} className="mr-2 px-4 py-2 bg-admin-bg text-admin-text rounded">Cancel</button>
                <button type="submit" className="px-4 py-2 bg-admin-accent text-admin-bg rounded">Save</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {state.addingCredits && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-admin-secondary p-6 rounded-lg">
            <h3 className="text-xl font-bold mb-4 text-admin-accent">Edit Credits</h3>
            <form onSubmit={handleAddCreditsSubmit}>
              <input
                type="number"
                value={state.creditsToAdd}
                onChange={(e) => setState(prevState => ({ ...prevState, creditsToAdd: parseInt(e.target.value) }))}
                placeholder="Number of credits"
                className="w-full p-2 mb-4 bg-admin-bg text-admin-text rounded"
              />
              <div className="flex justify-end">
                <button type="button" onClick={() => setState(prevState => ({ ...prevState, addingCredits: null }))} className="mr-2 px-4 py-2 bg-admin-bg text-admin-text rounded">Cancel</button>
                <button type="submit" className="px-4 py-2 bg-admin-accent text-admin-bg rounded">Edit Credits</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours}h ${minutes}m`;
};

export default AdminUserManagement;
