import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
  const { t } = useTranslation();
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error(t('errors.videoPlayback'), error);
      });
    }
  }, [t]);

  const handleVideoError = (e) => {
    console.error(t('errors.video'), e.target.error);
  };

  return (
    <div className="bg-[#0B0B0B] text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 text-center">
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold mb-6">
          {t('hero.title')}
        </h1>
        <p className="text-xl sm:text-2xl mb-10 max-w-3xl mx-auto">
          {t('hero.description')}
        </p>
        <Link
          to="/login"
          className="inline-block bg-[#FAD403] text-[#0B0B0B] py-3 px-8 rounded-full text-xl font-bold hover:bg-opacity-90 transition duration-300"
        >
          {t('hero.ctaButton')}
        </Link>
        <div className="mt-16 max-w-4xl mx-auto">
          <div className="aspect-w-16 aspect-h-9">
            <video 
              ref={videoRef}
              className="w-full h-full object-cover rounded-lg"
              loop 
              muted 
              playsInline
              preload="auto"
              onError={handleVideoError}
            >
              <source src="./demo.mp4" type="video/mp4" />
              {t('errors.videoUnsupported')}
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;