import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import AnalyticsMenu from './AnalyticsMenu';
import ParticleBackground from './ParticleBackground';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import italianFlag from '../src/assets/italian-flag.svg';
import ukFlag from '../src/assets/uk-flag.svg';

const AreaPersonale = () => {
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState(null);
  const [editingName, setEditingName] = useState(false);
  const [editingSurname, setEditingSurname] = useState(false);
  const [newName, setNewName] = useState('');
  const [newSurname, setNewSurname] = useState('');
  const [showAnalyticsMenu, setShowAnalyticsMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/me`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const userData = response.data.user;
        const verifiedPhone = localStorage.getItem('verifiedPhoneNumber');
        const updatedUser = {
          ...userData,
          verifiedPhoneNumber: verifiedPhone || userData.verifiedPhoneNumber
        };
        setUser(updatedUser);
        setNewName(updatedUser.nome);
        setNewSurname(updatedUser.cognome);
        localStorage.setItem('user', JSON.stringify(updatedUser));
      } catch (error) {
        console.error('Error fetching user data:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('verifiedPhoneNumber');
        navigate('/login');
      }
    };
    fetchUser();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('verifiedPhoneNumber');
    navigate('/');
  };

  const handleVoicebotConfig = () => {
    if (!user.verifiedPhoneNumber) {
      navigate('/verify-phone');
    } else {
      navigate('/voicebot-config');
    }
  };

  const handleDeletePhoneNumber = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/delete-phone-number`, {}, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setUser(prevUser => ({ ...prevUser, verifiedPhoneNumber: null, phoneNumberDeletions: (prevUser.phoneNumberDeletions || 0) + 1 }));
      localStorage.removeItem('verifiedPhoneNumber');
      localStorage.setItem('user', JSON.stringify({...user, verifiedPhoneNumber: null}));
      alert(response.data.message);
    } catch (error) {
      alert(error.response?.data?.error || t('areaPersonale.errors.deletePhoneNumber'));
    }
  };

  const handleUpdateName = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update-user`, 
        { nome: newName },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setUser(prevUser => ({ ...prevUser, nome: newName }));
      localStorage.setItem('user', JSON.stringify({...user, nome: newName}));
      setEditingName(false);
    } catch (error) {
      console.error('Errore nell\'aggiornamento del nome:', error.response ? error.response.data : error.message);
      alert(t('areaPersonale.errors.updateName'));
    }
  };
  
  const handleUpdateSurname = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update-user`, 
        { cognome: newSurname },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setUser(prevUser => ({ ...prevUser, cognome: newSurname }));
      localStorage.setItem('user', JSON.stringify({...user, cognome: newSurname}));
      setEditingSurname(false);
    } catch (error) {
      console.error('Errore nell\'aggiornamento del cognome:', error.response ? error.response.data : error.message);
      alert(t('areaPersonale.errors.updateSurname'));
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  if (!user) return <div className="text-white">{t('common.loading')}</div>;

  return (
    <div className="relative min-h-screen bg-[#0B0B0B] text-white p-4 sm:p-8 font-inter">
      <ParticleBackground />
      <div className="relative z-10 max-w-6xl mx-auto">
        <header className="mb-8 flex justify-between items-center">
          <h1 className="text-4xl font-bold text-[#FAD403]">
            {t('areaPersonale.title')}
          </h1>
          <div className="flex space-x-2">
            <button onClick={() => changeLanguage('it')} className="focus:outline-none">
              <img src={italianFlag} alt="Italiano" className="w-8 h-8" />
            </button>
            <button onClick={() => changeLanguage('en')} className="focus:outline-none">
              <img src={ukFlag} alt="English" className="w-8 h-8" />
            </button>
          </div>
        </header>
  
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <section className="bg-[#1E1E1E] p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-4">{t('areaPersonale.accountDetails')}</h2>
            <div className="space-y-3">
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                <strong>{t('areaPersonale.name')}:</strong>
                {editingName ? (
                  <div className="flex items-center mt-2 sm:mt-0">
                    <input
                      type="text"
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                      className="bg-gray-700 text-white px-2 py-1 rounded mr-2"
                    />
                    <button onClick={handleUpdateName} className="bg-green-500 text-white px-2 py-1 rounded mr-2">{t('common.save')}</button>
                    <button onClick={() => setEditingName(false)} className="bg-red-500 text-white px-2 py-1 rounded">{t('common.cancel')}</button>
                  </div>
                ) : (
                  <div className="flex items-center mt-2 sm:mt-0">
                    <span>{user.nome}</span>
                    <button onClick={() => setEditingName(true)} className="ml-2 bg-blue-500 text-white px-2 py-1 rounded">{t('common.edit')}</button>
                  </div>
                )}
              </div>
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                <strong>{t('areaPersonale.surname')}:</strong>
                {editingSurname ? (
                  <div className="flex items-center mt-2 sm:mt-0">
                    <input
                      type="text"
                      value={newSurname}
                      onChange={(e) => setNewSurname(e.target.value)}
                      className="bg-gray-700 text-white px-2 py-1 rounded mr-2"
                    />
                    <button onClick={handleUpdateSurname} className="bg-green-500 text-white px-2 py-1 rounded mr-2">{t('common.save')}</button>
                    <button onClick={() => setEditingSurname(false)} className="bg-red-500 text-white px-2 py-1 rounded">{t('common.cancel')}</button>
                  </div>
                ) : (
                  <div className="flex items-center mt-2 sm:mt-0">
                    <span>{user.cognome}</span>
                    <button onClick={() => setEditingSurname(true)} className="ml-2 bg-blue-500 text-white px-2 py-1 rounded">{t('common.edit')}</button>
                  </div>
                )}
              </div>
              <p><strong>{t('areaPersonale.email')}:</strong> {user.email}</p>
              <p><strong>{t('areaPersonale.verifiedPhoneNumber')}:</strong> {user.verifiedPhoneNumber || t('areaPersonale.notVerified')}</p>
              <p><strong>{t('areaPersonale.recenstarCredit')}:</strong> €{user.credit.toFixed(2)}</p>
            </div>
            <div className="mt-4 flex flex-col sm:flex-row sm:justify-between sm:items-center">
              {user.verifiedPhoneNumber ? (
                <div className="flex items-center mb-2 sm:mb-0">
                  <button 
                    onClick={handleDeletePhoneNumber} 
                    disabled={user.phoneNumberDeletions >= 3}
                    className={`px-4 py-2 rounded ${user.phoneNumberDeletions >= 3 ? 'bg-gray-500' : 'bg-red-600 hover:bg-red-700'} transition duration-300`}
                  >
                    {user.phoneNumberDeletions >= 3 ? t('areaPersonale.contactSupport') : t('areaPersonale.deletePhoneNumber')}
                  </button>
                  <Tippy content={t('areaPersonale.deletePhoneNumberTip', { count: 3 - user.phoneNumberDeletions })}>
                    <span className="ml-2 text-secondary cursor-pointer">ⓘ</span>
                  </Tippy>
                </div>
              ) : (
                <button 
                  onClick={() => navigate('/verify-phone')}
                  className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300 mb-2 sm:mb-0"
                >
                  {t('areaPersonale.verifyPhoneNumber')}
                </button>
              )}
              <button onClick={handleLogout} className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300">
                {t('common.logout')}
              </button>
            </div>
          </section>
  
          <section className="bg-[#1E1E1E] p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-4">{t('areaPersonale.chatbot')}</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-semibold mb-2">{t('areaPersonale.configureChatbot')}</h3>
                <p className="mb-4">{t('areaPersonale.chatbotConfigDescription')}</p>
                <Link to="/chatbot-config" className="bg-[#FAD403] text-[#0B0B0B] px-4 py-2 rounded hover:bg-opacity-90 transition duration-300 inline-block w-full sm:w-auto text-center">
                  {t('areaPersonale.configureChatbot')}
                </Link>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">{t('areaPersonale.testChatbot')}</h3>
                <p className="mb-4">{t('areaPersonale.testChatbotDescription')}</p>
                <Link 
                  to="/chatbot" 
                  className="bg-[#FAD403] text-[#0B0B0B] px-4 py-2 rounded hover:bg-opacity-90 transition duration-300 inline-block w-full sm:w-auto text-center"
                >
                  {t('areaPersonale.testChatbot')}
                </Link>
              </div>
            </div>
          </section>
  
          <section className="bg-[#1E1E1E] p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-4">{t('areaPersonale.voicebot')}</h2>
            <div className="flex flex-col items-center">
              <button 
                onClick={handleVoicebotConfig}
                className="bg-[#FAD403] text-[#0B0B0B] px-4 py-2 rounded hover:bg-opacity-90 transition duration-300 inline-block mb-4 w-full sm:w-auto"
              >
                {t('areaPersonale.configureVoicebot')}
              </button>
              <p className="text-sm text-gray-300 text-center">
                {t('areaPersonale.voicebotDescription')}
              </p>
            </div>
          </section>
  
          <section className="bg-[#1E1E1E] p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-4">{t('areaPersonale.analytics')}</h2>
            <button 
              onClick={() => setShowAnalyticsMenu(!showAnalyticsMenu)}
              className="bg-[#FAD403] text-[#0B0B0B] px-4 py-2 rounded hover:bg-opacity-90 transition duration-300 mb-4 w-full sm:w-auto"
            >
              {showAnalyticsMenu ? t('areaPersonale.hideAnalytics') : t('areaPersonale.viewAnalytics')}
            </button>
            <AnalyticsMenu showAnalyticsMenu={showAnalyticsMenu} />
          </section>
        </div>
  
        <footer className="text-center mt-8">
          <Link to="/" className="bg-[#FAD403] text-[#0B0B0B] px-6 py-3 rounded text-lg font-semibold hover:bg-opacity-90 transition duration-300 inline-block">
            {t('common.backToHome')}
          </Link>
        </footer>
      </div>
    </div>
  );
};

export default AreaPersonale;