import React from 'react';
import { Link } from 'react-router-dom';

const AdminSidebar = () => {
  return (
    <div className="bg-admin-secondary text-admin-text w-64 min-h-screen p-4">
      <h2 className="text-xl font-bold mb-4">Admin Panel</h2>
      <nav>
        <ul>
          <li className="mb-2">
            <Link to="/admin" className="hover:text-admin-accent">Dashboard</Link>
          </li>
          <li className="mb-2">
            <Link to="/admin/users" className="hover:text-admin-accent">User Management</Link>
          </li>
          <li className="mb-2">
            <Link to="/admin/stats" className="hover:text-admin-accent">Statistics</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AdminSidebar;